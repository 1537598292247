import {STORAGE_PERSONAL_DATA} from '../../../../environments/environment.prod';
import { Injectable, NgZone } from '@angular/core';
import { DBService } from 'src/app/services/db.service';
import { UtilService } from 'src/app/services/util.service';
import { Corona, CoronaGroup } from 'src/app/pages/shared/corona/corona.model';
import { STORAGE_CORONA, STORAGE_PHONE_CONFIRMATIONS } from 'src/environments/constant';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { LoadingController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { PhoneService } from 'src/app/services/phone.service';

@Injectable({
  providedIn: 'root'
})
export class CoronaService {

  loggedIn: { [restaurantKey: string]: CoronaGroup } = {};

  dbGroups$: Observable<any[]>;

  initialLoaded: boolean = false
  public get currentGroup() {
    if(!this.initialLoaded) {
      this.initialLoaded = true
      this.loadFromStorage()
    }

    //8h valid
    let guests = undefined
    if (this.loggedIn[this.db.currentRestaurantKey] && this.loggedIn[this.db.currentRestaurantKey].until == -1 && this.loggedIn[this.db.currentRestaurantKey].from > new Date().getTime() - 1000 * 60 * 60 * 8) {
      return this.loggedIn[this.db.currentRestaurantKey]
    } else if(this.loggedIn[this.db.currentRestaurantKey]){
      guests = this.loggedIn[this.db.currentRestaurantKey].guests   //load guests from local storage
    }else{
      guests = [new Corona()]
      let key = localStorage.getItem(STORAGE_PERSONAL_DATA)

      //adresse daten laden aus storage
      if (key) {
        let personalData = JSON.parse(key)
        guests[0].name = personalData.name
        guests[0].address = personalData.address
        guests[0].phone = personalData.phone
      }
    }

    this.loggedIn[this.db.currentRestaurantKey] = new CoronaGroup(this.db.currentRestaurantKey)
    this.loggedIn[this.db.currentRestaurantKey].guests = guests
    
    if(this.userService.currentTable){
      this.loggedIn[this.db.currentRestaurantKey].place = this.userService.currentTable.name
    }

    return this.loggedIn[this.db.currentRestaurantKey]
  }

  get phone(){
    if(this.loggedIn[this.db.currentRestaurantKey].guests && this.loggedIn[this.db.currentRestaurantKey].guests.length > 0 && this.loggedIn[this.db.currentRestaurantKey].guests[0].phone && this.loggedIn[this.db.currentRestaurantKey].guests[0].phone.length > 4){
      let p = this.phoneService.getNumber(this.loggedIn[this.db.currentRestaurantKey].guests[0].phone)
      if(p && p.phone_number) return p.phone_number
    } 
    return ""
  }

  public set currentGroup(coronaGroup: CoronaGroup) {
    this.loggedIn[this.db.currentRestaurantKey] = coronaGroup
  }

  constructor(private util: UtilService, private db: DBService, private userService: UserService, private phoneService: PhoneService) { 

  }

  selectRestaurant(){
    let last24hours: number = new Date().getTime() - 1000 * 60 * 60 * 24
    this.dbGroups$ = this.db.coronaList(this.db.currentRestaurantKey, last24hours)
  }

  loadFromStorage(): boolean {
    let res = localStorage.getItem(STORAGE_CORONA)
    if (res) {
      this.loggedIn = JSON.parse(res)
      return true
    }
    return false
  }

  saveToStorage() {
    localStorage.setItem(STORAGE_CORONA, JSON.stringify(this.loggedIn))
  }


}
