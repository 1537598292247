import { Injectable } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { AngularFireStorage } from 'angularfire2/storage';
import { STORAGE_ALL_RESTAURANTS_ICON_MAP, STORAGE_ALL_RESTAURANTS_ICONSIZE_MAP } from 'src/environments/environment';

import { UtilService } from 'src/app/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService { 

  constructor(private camera: Camera, public storage: AngularFireStorage, private util:UtilService) {

  }




  /*
  capturePicture() {
    const options: CameraOptions = {
      quality: 90,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      targetHeight: 80,
      targetWidth: 100
    }

    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      let base64Image = 'data:image/jpeg;base64,' + imageData;
    }, (err) => {
      // Handle error
    });
  }
*/



  async captureImage2() {
    const options: CameraOptions = {
      quality: 90,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      allowEdit: true,
      targetWidth: 1000,
      targetHeight: 400
    }

    return await this.camera.getPicture(options)
  }


  image;
  createUploadTask(file: string, restaurantKey: string): void {

    const filePath = `restaurants/${restaurantKey}/logo.jpg`;

    this.image = 'data:image/jpg;base64,' + file;
    this.base64ImageFull = "data:image/jpeg;base64," + file; //von vacabo
    this.base64Image = file; //von vacabo
    this.restaurantImgMap[restaurantKey] = "data:image/jpeg;base64," + file; //von vacabo
    localStorage.setItem(STORAGE_ALL_RESTAURANTS_ICON_MAP,JSON.stringify(this.restaurantImgMap))
    this.storage.ref(filePath).putString(this.image, 'data_url');
  }

  async uploadHandler(restaurantKey: string) {
    const base64 = await this.captureImage2();
    this.createUploadTask(base64, restaurantKey);
  }


  /*
  
    uploadImage(img: any, key: any) {
      this.userIconMap[key] = "data:image/jpeg;base64," + img;
  
      this.storage.ref(filePath).putString(this.image, 'data_url');
      this.profilePictureRef = this.firebaseApp.storage().ref('/users/');
      this.profilePictureRef.child("profilimg").child(key + '.png')
        .putString(img, 'base64', { contentType: 'image/png' })
        //  .putString(this.myImage, 'base64', { contentType: 'image/png' })
        .then((savedPicture: any) => {
  
          if (this.loggedUser != null) {
            this.loggedUser.avatar = savedPicture.downloadURL;
  
            this.updateUser(this.loggedUser.key, this.loggedUser)
          }
  
        });
    }
  */























  //***********************************************************************************************
  //***********************************************************************************************
  //***********************************************************************************************
  //***********************************************************************************************
  //          Firebase Storage
  //***********************************************************************************************
  //***********************************************************************************************
  //***********************************************************************************************
  //***********************************************************************************************

  //User Key / Base 64 string (will be only used in the web app)
  restaurantImgMap: { [restaurantKey: string]: string; } = {};
  restaurantImgSizeMap: { [restaurantKey: string]: number; } = {}; //necessary for metadata comparison

  public profilePictureRef: any;


  public base64Image: string;
  public base64ImageFull: string;







  //Lädt den Avatar als Base64 String
  private loadRestaurantImage(restaurantKey: string, forceReload:boolean = false) {
    this.util.log("loadRestaurantImage, Restaurant Key und Force Reload?", restaurantKey, forceReload)
    //Hier kann definitiv ueberlegt werden, ob evtl. die groesse verglichen werden sollte
    if (this.restaurantImgMap[restaurantKey] != null && !forceReload) {
      this.util.log("Image found in Restaurant Map")

      return this.restaurantImgMap[restaurantKey];
    }

    var myReference = this;

    //https://firebase.google.com/docs/storage/web/download-files
    const filePath = `restaurants/${restaurantKey}/logo.jpg`;
    this.storage.ref(filePath).getDownloadURL().subscribe(function (url: any) {
      myReference.util.log("Load Img from Firebase", url)

      if (url) {
        myReference.getBinary(url, restaurantKey); //async, hier wird das Bild auch in die Map gesetzt
      }else{
        myReference.restaurantImgMap[restaurantKey] = ''
      }

    })
  }


  
  public loadRestaurantImageBasedOnMetadataComparison(restaurantKey: string, lastSize:number) {
   // this.util.log("!!!!!!!!!!!!!!", this.userIconMap[restaurantKey].length)
    const filePath = `restaurants/${restaurantKey}/logo.jpg`;
    let myRef = this;
    this.storage.ref(filePath).getMetadata().subscribe(function (metadata: any) {
      myRef.util.log("-------------------", metadata)
      if(!metadata || !metadata.size || metadata.size !== lastSize || !lastSize){
        myRef.loadRestaurantImage(restaurantKey, true)
      }else{
        myRef.loadRestaurantImage(restaurantKey, false)
      }

      if(metadata.size){
        myRef.restaurantImgSizeMap[restaurantKey] = metadata.size
        localStorage.setItem(STORAGE_ALL_RESTAURANTS_ICONSIZE_MAP, JSON.stringify(myRef.restaurantImgSizeMap))
      }

    })
  }



  //*******************************************************
  // Helper MEthods
  //*******************************************************
  //http://stackoverflow.com/questions/7370943/retrieving-binary-file-content-using-javascript-base64-encode-it-and-reverse-de

  private getBinary(file: any, restaurantKey: any) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", file, true);
    xhr.overrideMimeType("text/plain; charset=x-user-defined");
    var myReference = this;
    xhr.addEventListener('load', function (event) {
      if (xhr.status >= 200 && xhr.status < 300) {
        myReference.restaurantImgMap[restaurantKey] = 'data:image/jpeg;base64,' + myReference.base64Encode(xhr.responseText);
        localStorage.setItem(STORAGE_ALL_RESTAURANTS_ICON_MAP, JSON.stringify(myReference.restaurantImgMap))

        //   myReference.compareMetadata4ProfilImg(restaurantKey)
      } else {
        console.warn("Not found");
      }
    });

    xhr.send();

    //https://wiki.selfhtml.org/wiki/JavaScript/XMLHttpRequest
  }

  private base64Encode(str: any) {
    let CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    let out = "", i = 0, len = str.length, c1: any, c2: any, c3: any;
    while (i < len) {
      c1 = str.charCodeAt(i++) & 0xff;
      if (i == len) {
        out += CHARS.charAt(c1 >> 2);
        out += CHARS.charAt((c1 & 0x3) << 4);
        out += "==";
        break;
      }
      c2 = str.charCodeAt(i++);
      if (i == len) {
        out += CHARS.charAt(c1 >> 2);
        out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
        out += CHARS.charAt((c2 & 0xF) << 2);
        out += "=";
        break;
      }
      c3 = str.charCodeAt(i++);
      out += CHARS.charAt(c1 >> 2);
      out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
      out += CHARS.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
      out += CHARS.charAt(c3 & 0x3F);
    }
    return out;
  }




}
