export class OpeningTime {
    constructor(public weekday: string, public startTime: string, public endTime: string) { }



    public static initDefaultZeiten(): OpeningTime[] {
        let zeiten: OpeningTime[] = []

        zeiten.push(new OpeningTime('MO', '16:00', '00:00'))
        zeiten.push(new OpeningTime('DI', '16:00', '00:00'))
        zeiten.push(new OpeningTime('MI', '16:00', '00:00'))
        zeiten.push(new OpeningTime('DO', '16:00', '00:00'))
        zeiten.push(new OpeningTime('FR', '16:00', '02:00'))
        zeiten.push(new OpeningTime('SA', '16:00', '02:00'))

        return zeiten
    }


    public static getWeekdayNo(weekday:string):number{
        switch(weekday){
            case 'SO': return 0;
            case 'MO': return 1;
            case 'DI': return 2;
            case 'MI': return 3;
            case 'DO': return 4;
            case 'FR': return 5;
            case 'SA': return 6;
            default: return 0;
        }
    }

    public static getStringTimeAsDate(stringtime: string):Date{
        let startHour:number = +(stringtime.substring(0,2))
        let startMin:number = +(stringtime.substring(3))
        let startDate:Date = new Date()
        startDate.setHours(startHour)     
        startDate.setMinutes(startMin)  

        return startDate
    }

    public getOpeningTimeAsDate():Date{
        return OpeningTime.getStringTimeAsDate(this.startTime)
    }

    public getClosingTimeAsDate():Date{
        return OpeningTime.getStringTimeAsDate(this.endTime)
    }
}
