import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SettingsProvider {
 
    private theme: BehaviorSubject<String>;
    public lastActive:string;
 
    constructor() {
        this.theme = new BehaviorSubject('light-theme');
        this.lastActive = 'light-theme'
    }
 
    setActiveTheme(val) {
        if(val === 'dark-theme' || val === 'light-theme'){
            this.lastActive = val
        }
        this.theme.next(val);
    }
 
    getActiveTheme() {
        return this.theme.asObservable();
    }

    _state = {};
    
        //return a clone of state
        get state() {
            return this._state = this.clone(this._state);
        }
    
        private clone(object){
            return JSON.parse(JSON.stringify(object))
        }
    
        get(prop?:any) {
            const state = this.state;
            return state.hasOwnPropertyprop( prop)? state[prop] : state;
        }
    
        set(prop:string,value:any){
            //update the specified state property with the value
            return this._state[prop] = value;
        }
}