import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fcmUrl } from 'src/environments/constant';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';

export const TOPIC_RESTAURANT_IS_OPENING: string = 'opening'
export const TOPIC_RESTAURANT_REMIND_OPENING: string = 'remindopening'
export const TOPIC_NEW_CONTACT_MESSAGE: string = 'contactmessage'
export const TOPIC_CHECK_RESTAURANT: string = 'checkrestaurant'
export const TOPIC_NEW_ORDER: string = 'neworder'
export const TOPIC_ANALYTICS: string = 'analytics'
export const TOPIC_ORDER: string = 'order'

import * as firebase from 'firebase';


import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';

const { PushNotifications } = Plugins;

// with type support
import { FCM } from '@capacitor-community/fcm';
import { OrderGroup } from 'src/app/models/order.model';
import { MailService } from 'src/app/services/mail.service';
const fcm = new FCM();
// alternatively - without types
const { FCMPlugin } = Plugins;




@Injectable({
  providedIn: 'root'
})
export class PushService {

  token = "";

  constructor(private http: HttpClient, private u: UtilService, private mailService: MailService) {
    this.initOnNotification();
  }

  initOnNotification() {
    //this.sendPush("j","jj","test99","df").subscribe((res: any) => console.log("aiaiaiaiaiai",res))
    let _this = this;
    try {
      if (this.u.platform.is('ios') || this.u.platform.is('android')) {
        PushNotifications.requestPermission().then((res1) => {
          console.log(`requestPermission res`, res1)
          PushNotifications.register()
            .then(() => {
              fcm.getToken()
                .then((res) => {
                  console.log(`getFCMToken res`, res)
                  _this.token = res.token;
                  _this.initMobile();
                })
                .catch((err) => console.error(`getFCMToken`, err));
            })
            .catch((err) => console.error(`PushNotifications.register()`, err));
        }).catch((err) => console.error(`PushNotifications.requestPermission()`, err));
      } else {
        const messaging = firebase.messaging();
        messaging.onMessage((payload) => {
          console.log('[firebase-messaging-sw.js] front', payload);
          let text = payload.notification.title + ": " + payload.notification.body
          this.u.presentToast(text, 120000, 'toast-success')
        });

        messaging.getToken({ vapidKey: 'BDjqu0BNG5Zw7rgN0eZ-BDbQX9DpkMJhV4ifucfHHagLRA1A8e2qcWruCwkSxbCMuPKPiUwp4d9V7WhsH_Pj8I8' }).then((token) => {
          console.log(`getFCMWebToken`, token)
          _this.token = token;
          _this.initWeb();
          //_this.subscribeToTopic("test99")
        })

      }
    } catch (e) {
      console.log("error in PUSH", e)
    }
  }

  sendPushToCustomer(orderGroup: OrderGroup, title: string, body: string, topic: string, tag: string, restaurantKey?: string): Observable<any> {
    if (orderGroup.mail && orderGroup.mail.length > 4) this.mailService.sendMailToCustomer(title, body, orderGroup.mail).subscribe(() => { })
    return this.sendPush(title, body, topic, tag, restaurantKey)
  }

  sendPush(title: string, body: string, topic: string, tag: string, restaurantKey?: string): Observable<any> {
    let data: any = { title: title, body: body, topic: topic, tag: tag, restaurantKey: restaurantKey }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    console.log("send notification", topic, fcmUrl, data)

    return this.http.post<any>(`${fcmUrl}/push`, data, { observe: 'response', headers: headers })
  }

  subscribeToTopic(topic: string, restaurantKey?: string) {
    if (restaurantKey) topic = topic + '-' + restaurantKey
    console.log("subscribeToTopic", topic)

    if (this.u.isMobileApp2) this.subscribeApp(topic)
    else this.subscribeWeb(topic)
  }

  unsubscribeToTopic(topic: string, restaurantKey?: string) {
    if (restaurantKey) topic = topic + '-' + restaurantKey

    if (this.u.isMobileApp2) this.unsubscribeApp(topic)
    else this.unsubscribeWeb(topic)
  }

  subscribeApp(topic) {
    fcm
      .subscribeTo({ topic: topic })
      .then((r) => console.log(`Successfully subscribed to ${topic}`))
      .catch((err) => console.error(`Error while subscribing to ${topic}`, err));
  }

  unsubscribeApp(topic) {
    fcm
      .unsubscribeFrom({ topic: topic })
      .then((r) => console.log(`Successfully unsubscribed from ${topic}`))
      .catch((err) => console.error(`Error while unsubscribing from ${topic}`, err));
  }

  /* WEB */
  subscribeWeb(topic) {
    let _this = this
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    this.http.get<any>(`${fcmUrl}/subscribe/${_this.token}/${topic}`, { observe: 'response', headers: headers }).subscribe((val: any) => {
      console.log("Subscribe to topic was successfull", _this.token, topic)
    }, err => {
      console.log("Subscribe to topic was unsuccessfull", _this.token, topic, err)
    });
  }

  unsubscribeWeb(topic) {
    let _this = this
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    this.http.get<any>(`${fcmUrl}/unsubscribe/${_this.token}/${topic}`, { observe: 'response', headers: headers }).subscribe((val: any) => {
      console.log("Unsubscribe to topic was successfull", _this.token, topic)
    }, err => {
      console.log("Unsubscribe to topic was unsuccessfull", _this.token, topic, err)
    });
  }


  /* Capctha */
  verifyCaptcha(token) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(`${fcmUrl}/captcha/${token}`, { observe: 'response', headers: headers })
  }



  initMobile() {
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        let text = notification.title + ": " + notification.body
        this.u.presentToast(text, 120000, 'toast-success')
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        //  alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }


  initWeb() {
    // §§§ WITHOUT PLUGIN, BUT PLAIN.... NO SUBSCRIBE FOR APP HERE + SUBSCRIBE on SERVER DIDNT WORK FOR ANDROID """"

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting


    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        //  PushNotifications.register();
      } else {
        // Show some error
      }
    });

    /*
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        console.log("rrrrrrrrrrrrrrrr", token.value)
        alert('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log("zzzzzzzzzzzzzzzzzz", error)
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
    */

  }


}





  /*
//  this.fcm.onNotification().subscribe(data => {
let data = { wasTapped: true }
console.log(data)
if (data.wasTapped) {
  console.log("Received in background");
} else {
  console.log("Received in foreground");
};
//   });
*/


