import { Component, OnInit, NgZone, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { DBService } from 'src/app/services/db.service';
import { AlertController, IonContent, IonFooter, } from '@ionic/angular';
import { ImageService } from 'src/app/services/image.service';
import { WifiService } from 'src/app/services/wifi.service';
import { AlertService } from 'src/app/services/alert.service';
import { UtilService } from 'src/app/services/util.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.service';
import { DisplayService } from 'src/app/services/display.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Events } from 'src/app/services/events.service';
import { PushService } from 'src/app/services/push.service';
import { BasketService } from 'src/app/services/basket.service';
import { STORAGE_CACHE_BASKET } from 'src/environments/environment.prod';
import { Platform } from '@ionic/angular';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GMAPS_API_KEY, ANALYTICS_EV_SELECT_RESTAURANT, ANALYTICS_EV_GOOGLE_MAPS_SELECTED } from 'src/environments/constant';
import { Restaurant } from 'src/app/models/restaurant.model';

@Component({
  selector: 'app-restaurant-list',
  templateUrl: './restaurant-list.page.html',
  styleUrls: ['./restaurant-list.page.scss'],
})
export class RestaurantListPage implements OnInit, AfterViewInit {

  map: any;

  //imageService & restaurantService are required in HTML page.
  constructor(private router: Router, public db: DBService, private ngZone: NgZone, private alertCtrl: AlertController, public userService: UserService,
    private wifiService: WifiService, public imageService: ImageService, public util: UtilService, public restaurantService: RestaurantService,
    public displayService: DisplayService, private alert: AlertService, private events: Events, private pushService: PushService,
    private route: ActivatedRoute, private basketService: BasketService,
    public platform: Platform, private geolocation: Geolocation, private locationAccuracy: LocationAccuracy) {


      (<any>window).ionicPageRef = {
        zone: this.ngZone,
        component: this
      };
  }

  ngOnInit() {
    this.db.setPage('home')
    this.db.currentRestaurantKey = ""
    //*** Push only temporaer ***/
    //this.pushService.sendPush("Titel Beispiel","Body Beispiel","NACHRICHT","TAG_ZUM_AGGREGIEREN").subscribe(val => console.log(val))
  }

  ngAfterViewInit(): void {
    if (!this.userService.hasFavourites) {
      this.displayService.favEnabled = false;
      this.displayService.furtherLocalsEnabled = true
    } else {
      this.displayService.favEnabled = true;
      this.displayService.furtherLocalsEnabled = false
    }

    //TODO: Default
    this.displayService.favEnabled = false;
    this.displayService.furtherLocalsEnabled = true

    this.initMapData();
    this.initMap()

    this.displayService.elGoogleMapCanvas = this.elGoogleMapCanvas
  }

  async deleteRestaurant(restaurantKey: string) {
    this.alert.presentAlertConfirm(this, (ref) => { ref.db.removeRestaurantsWithDependency(restaurantKey) }, 'Lokal löschen?', 'Sicher, dass du dieses Lokal löschen möchtest?')
  }

  createNewRestaurant(userID, lokalName) {
    this.alert.presentAlertConfirm(this, (ref) => {
      this.restaurantService.createNewRestaurant(this.db.loggedUserUID, 'Unbekanntes Lokal')
      this.events.subscribe('restaurant-created', (restaurantID) => {
        this.events.unsubscribe('restaurant-created')
        if (restaurantID) {
          this.db.selectRestaurant(restaurantID)
          this.ngZone.run(() => this.router.navigate(['/owner-home']))
        }
      })
    }, 'Möchtest du ein neues Lokal erstellen?', 'Aktuell kann das Lokal dann nur über das BARELA-Team gelöscht werden.')

  }

  statusName(restaurant) {
    if (!restaurant) return "Unbekannt"
    if (restaurant.isApprovedByMamingo == -1) return "Offen"
    if (restaurant.isApprovedByMamingo == -2) return "Abgelehnt"
    if (restaurant.isApprovedByMamingo == 0) return "In Prüfung"
    return "Akzeptiert"
  }





  /**************************************************************************************************************************************** */





























  selectRestaurant(key: string) {
    this.db.selectRestaurant(key)
    this.db.addAnalytics(ANALYTICS_EV_SELECT_RESTAURANT)
    this.basketService.loadBasket(STORAGE_CACHE_BASKET)
    if ((this.db.loggedUserUID && (this.db.isOwner || this.db.isEmployee)) || this.util.isAdmin) {
      this.ngZone.run(() => this.router.navigate(['/owner-home']))
    } else {
      this.ngZone.run(() => this.router.navigate(['/customer-home']))
    }
  }

  register(view: string) {
    this.ngZone.run(() => this.router.navigate(['/register/' + view]))
  }


  test444() {
    //this.pushService.test1();
  }

  testt() {
    //  this.pushService.test2();
  }

  test(num: number) {

    if (num === 0) {
    } else if (num === 1) {
      this.wifiService.test1()

    } else if (num === 2) {
      this.wifiService.test2()

    } else if (num === 3) {
      this.wifiService.test3()

    } else if (num === 4) {
      this.wifiService.test4()

    } else {
      this.wifiService.test5()
    }
    //this.wifiService.enableLocalLocationAppPermission()
    //0. Check, ob Networks da sind.. Wenn nicht, weiter:
    //1. Check ob Wifi enabled ist => Falls nicht, Info, dass Wifi enabled sein muss; Wie merken, dass Wifi da ist??? TODO
    //2. Check ob ich networks erhalte...
    //2. Check ob GPS an ist (wie?? TODO), => Info
  }


  ////////////////////////////MAPS////////////////////////////////















































  calculateDistance(lat1: number, lat2: number, long1: number, long2: number) {
    let p = 0.017453292519943295;    // Math.PI / 180
    let c = Math.cos;
    let a = 0.5 - c((lat1 - lat2) * p) / 2 + c(lat2 * p) * c((lat1) * p) * (1 - c(((long1 - long2) * p))) / 2;
    let dis = (12742 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
    dis = Math.round(dis * 10) / 10
    return dis;
  }

  userCoords = {lat: undefined, lng: undefined}
  getUserLocation() {
    this.initMap()

    this.geolocation.getCurrentPosition().then((resp) => {
      console.log("Location", resp)
      this.userCoords = { lat: resp.coords.latitude, lng: resp.coords.longitude }

      this.mapData.push(
        {
          "name": "",
          "address": "",
          "lat": resp.coords.latitude,
          "lng": resp.coords.longitude,
          "center": true,
          "icon": 'assets/imgs/maps/person.png',
          "key": '-1',
          "isuser": true
        })
      console.debug("Users Position (lat/long)", resp.coords.latitude, resp.coords.longitude)
      this.updateDistanceToRestaurants(resp.coords.latitude, resp.coords.longitude)
      this.initMap()
    }).catch((error) => {
      console.log('Error getting location', error);

      this.util.presentToast('Leider konnten wir dein Standort nicht ermitteln', 3000, 'toast-warning')

      this.initMapData(false);
      this.initMap()
    });
    /*
    Postition immer aktualisieren - brauchen wir nicht....
    let watch = this.geolocation.watchPosition();
    watch.subscribe((data) => {
      console.log(data)
    // data can be a set of coordinates, or an error (if an error occurred).
    // data.coords.latitude
    // data.coords.longitude
    });
*/
  }


  lastUpdateBrowserLocation: number = -1
  requestWebUserLocation() {
    let _this = this
    navigator.permissions.query({ name: 'geolocation' }).then(state => {
      console.log("Location Permission", state)
      if(!state || state.state == "denied") return

      navigator.geolocation.watchPosition(res => {
        if (res && res.coords) {
          if(new Date().getTime() < this.lastUpdateBrowserLocation + 300000) return //update only every 5 mins.
          this.lastUpdateBrowserLocation = new Date().getTime()
          
          console.log("found browser location: ", res.coords)
          _this.mapData.push(
            {
              "name": "",
              "address": "",
              "lat": res.coords.latitude,
              "lng": res.coords.longitude,
              "center": true,
              "icon": 'assets/imgs/maps/person.png',
              "key": '-1',
              "isuser": true
            })
          _this.updateDistanceToRestaurants(res.coords.latitude, res.coords.longitude)
          _this.initMap();
        }
      }, error => {
        console.error("web error", error)
        if (this.util.demo) {
          this.updateDistanceToRestaurants(48.1413653, 7.694022899999998)
          this.mapData.push(
            {
              "name": "",
              "address": "",
              "lat": 48.1413653,
              "lng": 7.6940,
              "center": true,
              "icon": 'assets/imgs/maps/person.png',
              "key": '-1',
              "isuser": true
            })
            _this.initMap();
        }
        this.initMapData();
      })
    })
  }

  get restaurantList(): any[] {
    let _this = this
    let restaurantKeys = []

    if (this.showOnlyFav)
      restaurantKeys = this.db.getRestaurantKeys('CUSTOMER').filter(restaurantKey => {
        return _this.userService.favouriteRestaurants.indexOf(restaurantKey) >= 0
      });
    else
      restaurantKeys = this.db.getRestaurantKeys('CUSTOMER')

    return restaurantKeys.sort(function (a: string, b: string) {
      //both are favs
      if (_this.userService.favouriteRestaurants.indexOf(a) >= 0 && _this.userService.favouriteRestaurants.indexOf(b) >= 0) {
        if (_this.restaurantService.distanceToLocation[a] && _this.restaurantService.distanceToLocation[b]) {
          if (_this.restaurantService.distanceToLocation[a] < _this.restaurantService.distanceToLocation[b]) {
            return -1
          } else if (_this.restaurantService.distanceToLocation[a] > _this.restaurantService.distanceToLocation[b]) {
            return 1
          } else {
            return 0
          }
        } else {
          if (_this.db.restaurants[a].name.toUpperCase() < _this.db.restaurants[b].name.toUpperCase()) {
            return -1
          } else if (_this.db.restaurants[a].name.toUpperCase() > _this.db.restaurants[b].name.toUpperCase()) {
            return 1
          } else {
            return 0
          }
        }
      } else if (_this.userService.favouriteRestaurants.indexOf(a) >= 0 && _this.userService.favouriteRestaurants.indexOf(b) < 0) {
        return -1
      } else if (_this.userService.favouriteRestaurants.indexOf(a) < 0 && _this.userService.favouriteRestaurants.indexOf(b) >= 0) {
        return 1
      } else {
        //both are not favs
        if (_this.restaurantService.distanceToLocation[a] && _this.restaurantService.distanceToLocation[b]) {
          if (_this.restaurantService.distanceToLocation[a] < _this.restaurantService.distanceToLocation[b]) {
            return -1
          } else if (_this.restaurantService.distanceToLocation[a] > _this.restaurantService.distanceToLocation[b]) {
            return 1
          } else {
            return 0
          }
        } else {
          //pref Ninos
          if(_this.util.randomNumber > 0.4 &&  a=="-MSJNa_skMdnSq-Af3SU") return -1
          else if (_this.util.randomNumber > 0.4 && b=="-MSJNa_skMdnSq-Af3SU") return 1
          
          //random
          if (_this.util.randAlphabet.indexOf(_this.db.restaurants[a].name.substring(1,2).toUpperCase()) > _this.util.randAlphabet.indexOf(_this.db.restaurants[b].name.substring(1,2).toUpperCase())) {
            return -1
          } else if (2>1) {
            return 1
          }

          if (_this.db.restaurants[a].name.toUpperCase() < _this.db.restaurants[b].name.toUpperCase()) {
            return -1
          } else if (_this.db.restaurants[a].name.toUpperCase() > _this.db.restaurants[b].name.toUpperCase()) {
            return 1
          } else {
            return 0
          }
        }
      }
    });

  }


  requestUserLocation() {
    this.db.addAnalytics(ANALYTICS_EV_GOOGLE_MAPS_SELECTED)
    
    this.setView(this.VIEW_MAP)
    let myRef = this
    this.initMapData(false);

    //*** Entfernung berechnen ***/
    if (this.userCoords.lat && this.userCoords.lng) {
      this.updateDistanceToRestaurants(this.userCoords.lat, this.userCoords.lng)
      this.mapData.push(
        {
          "name": "",
          "address": "",
          "lat": this.userCoords.lat,
          "lng": this.userCoords.lng,
          "center": true,
          "icon": 'assets/imgs/maps/person.png',
          "key": '-1',
          "isuser": true
        })
      this.initMap();
    }

    if (!this.util.isMobileApp2) {
      this.requestWebUserLocation();
      return
    }

    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      myRef.util.log("Can Request Location Accuracy?", canRequest)
      //if(canRequest) {
      // the accuracy option will be ignored by iOS
      myRef.locationAccuracy.request(myRef.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
        () => {
          myRef.util.log('Request successful')
          this.getUserLocation()
        },
        error => { 
          myRef.util.error('Error requesting location permissions 2', error)
          // this.getUserLocation()
        }
      );
    }, (error) => {
      myRef.util.error('Error requesting location permissions 1', error)

      if (this.util.demo) {
        this.updateDistanceToRestaurants(48.1413653, 7.694022899999998)
        this.mapData.push(
          {
            "name": "",
            "address": "",
            "lat": 48.1413653,
            "lng": 7.6940,
            "center": true,
            "icon": 'assets/imgs/maps/person.png',
            "key": '-1',
            "isuser": true
          })
        this.initMap();
      }
    });
  }

  updateDistanceToRestaurants(lat, long) {
    let distance: number = 0
    for (let restaurantKey of Object.keys(this.db.restaurants)) {
      if (this.db.restaurants[restaurantKey].location) {
        distance = this.calculateDistance(lat, this.db.restaurants[restaurantKey].location.lat, long, this.db.restaurants[restaurantKey].location.lng)
        this.restaurantService.distanceToLocation[restaurantKey] = distance
      }
    }
  }

  index = 0

  showOnlyFav: boolean = false
  filterFav() {
    this.currentView = this.VIEW_LIST
    this.showOnlyFav = true
  }

  setView(view: string) {
    this.currentView = view
    this.showOnlyFav = false
  }

  async initMap() {
    this.markers = []
    let myRef = this
    const googleMaps = await getGoogleMaps(GMAPS_API_KEY);

    /*******************************
    let latLng = new google.maps.LatLng(-34.9290, 138.6010);

    let mapOptions = {
      center: latLng,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    *********************************************** */


    const mapEle = this.elGoogleMapCanvas.nativeElement;

    const map = new googleMaps.Map(mapEle, {
      center: myRef.mapData.find((d: any) => d.center),
      zoom: 10,
      disableDefaultUI: true
    });


    this.index = 0
    this.mapData.forEach((markerData: any) => {
      this.index++;

      const marker = new googleMaps.Marker({
        position: markerData,
        map,
        title: markerData.name,
        icon: markerData.icon,
        restaurantkey: markerData.key
      });

      this.markers.push(marker)

      /*
     
*/
      //Make only restaurants clickable
      if (markerData.isuser !== true) {



        marker.addListener('click', function () {
          if (myRef.lastinfowindow) {
            myRef.lastinfowindow.close();
          }

          myRef.openRestaurantKey = marker.restaurantkey
          if (myRef.userService.favouriteRestaurants.indexOf(marker.restaurantkey) !== -1) {
            markerData.favIcon = 'star'
          } else {
            markerData.favIcon = 'star-outline'
          }

          if (myRef.db.restaurants[marker.restaurantkey] && myRef.db.restaurants[marker.restaurantkey].isOpen) {
            markerData.openState = "<strong style='color: #0fac31;'>Geöffnet</b>"
          } else {
            markerData.openState = "<strong style='color: red;'>Geschlossen</b>"
          }

          let _content

          console.log(markerData)
          _content = ` <span style="color: black; font-size:1.3em;">${markerData.name}</span><br />
                                    <!-- <small style="color: black;">${markerData.address}</small><br /> -->
                                    <table style="width: 100%;">
                                      <tr>
                                        <td style="width: 90%"><span style='line-height: 2.3em; vertical-align: bottom;'>${markerData.openState}</span> </td>
                                        <td style="width: 90%; text-align:right">
                                        <ion-icon style='font-size: 2.5em; color:#F1C40F; text-align: right;'
                                          onClick='window.ionicPageRef.zone.run(function () { window.ionicPageRef.component.clickOnFavorite(
                                            this
                                          ) })' name='${markerData.favIcon}'>
                                        </ion-icon>
                                      </td>
                                    </tr>
                                    </table>

                                    <div style='width: 100%;text-align:center; background-color: #4286F5; color: white; padding: 5px 0px; margin-top: 5px;'><a style='text-decoration:none; color: white;' href= 'http://maps.google.com/?q=${markerData.address}' target='_blank'>Route</a></div>`


          let infoWindow = new googleMaps.InfoWindow({
            content: _content,

          });

          myRef.lastinfowindow = infoWindow

          // infoWindow.setContent("<div>sdf<br><input type='submit' id='butSubmit' value='Procurar' onclick='BarFind()'><div id='bar'></div></div>"); //????
          // infoWindow.open(map, marker);
          infoWindow.open(map, this);//????
        });
      }
    });

    googleMaps.event.addListenerOnce(map, 'idle', () => {
      mapEle.classList.add('show-map');
    });
  }

  hideAllInfoWindows(map) {
    this.markers.forEach(function (marker) {
      if (marker.infowindow)
        marker.infowindow.close(map, marker);
    });
  }


  clickOnFavorite(event) {
    this.userService.clickOnFavorite(this.openRestaurantKey)
    this.lastinfowindow.close();
    this.lastinfowindow = null
  }

  openContact() {
    this.ngZone.run(() => this.router.navigate(['/contact']))
  }

  openRegister() {
    this.ngZone.run(() => this.router.navigate(['/register/register']))
  }

  readonly VIEW_LIST: string = 'list'
  readonly VIEW_MAP: string = 'map'
  @ViewChild('elGoogleMapCanvas', { static: true }) elGoogleMapCanvas: ElementRef;

  mapData

  markers = []
  openRestaurantKey: string
  openRestaurantStarIcon: string
  lastinfowindow = null;


  currentView: string = this.VIEW_LIST

  initMapData(centerAllowed: boolean = true) {
    this.mapData = []

    for (let restaurantKey of Object.keys(this.db.restaurants)) {
      if (this.db.restaurants[restaurantKey].location && this.db.restaurants[restaurantKey].isActive &&
        this.db.restaurants[restaurantKey].isApprovedByMamingo === 99) {

        this.mapData.push(
          {
            "name": this.db.restaurants[restaurantKey].name,
            "address": this.db.restaurants[restaurantKey].address,
            "key": restaurantKey,
            "lat": this.db.restaurants[restaurantKey].location.lat,
            "lng": this.db.restaurants[restaurantKey].location.lng,
            "center": (true && centerAllowed),
            "icon": 'assets/imgs/maps/barela.png',
            "isuser": false,
            "isFav": this.userService.favouriteRestaurants.indexOf(restaurantKey) !== -1,
          })
      }
    }
  }

}












function getGoogleMaps(apiKey: string): Promise<any> {
  const win = window as any;
  const googleModule = win.google;
  if (googleModule && googleModule.maps) {
    return Promise.resolve(googleModule.maps);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.31`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      const googleModule2 = win.google;
      if (googleModule2 && googleModule2.maps) {
        resolve(googleModule2.maps);
      } else {
        reject('google maps not available');
      }
    };
  });
}

