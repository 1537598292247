import { Injectable } from '@angular/core';
import { Restaurant } from '../models/restaurant.model';
import { DBService } from './db.service';
import { PushService, TOPIC_RESTAURANT_IS_OPENING } from 'src/app/services/push.service';
import { UtilService } from 'src/app/services/util.service';
import { AlertService } from 'src/app/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  constructor(private db: DBService, public util: UtilService, private pushService: PushService, private alert: AlertService) { }

  public distanceToLocation: { [restaurantKey: string]: number } = {};

  createNewRestaurant(userID: string, restaurantName: string) {
    let r: Restaurant = new Restaurant(null)
    r.name = restaurantName
    r.isActive = false
    r.isApprovedByMamingo = -1
    r.owners.push(userID)
    this.db.addRestaurant(r)
  }

  getAmountBonuscards(restaurantKey: string): number {
    let now: number = new Date().getTime()
    if (this.db.restaurants[restaurantKey] && this.db.restaurants[restaurantKey].bonuskarten) {
      let amount: number = 0;
      for (let bonuskarte of this.db.restaurants[restaurantKey].bonuskarten) {
        if (bonuskarte.isActive && bonuskarte.startDate < now && bonuskarte.endDate > now)
          amount++
      }
      return amount
    }

    return 0
  }

  getAmountSpecials(restaurantKey: string): number {
    if (this.db.restaurants[restaurantKey] && this.db.restaurants[restaurantKey].special2) {
      let amount: number = 0;
      for (let special of this.db.restaurants[restaurantKey].special2) {
        if (special.isActive)
          amount++
      }
      return amount
    }

    return 0
  }


  /******************************************************************************************************************************************************* */
  // FÜR OPEN / CLOSE STATE : KANN BESSER GELOEST WERDEN!!!!!
  /******************************************************************************************************************************************************* */
  toggleOpenClose() {
    //if lokal provides option, to "eat-here" it must set up wifi and tables
    if (this.db.currentRestaurant.orderMode["eat-here"] == true) {
      /*
      if (!this.db.currentRestaurant.wifi2 || this.db.currentRestaurant.wifi2.length <= 0) {
        this.db.currentRestaurant.isOpen = false;
        this.util.presentToast('Lokal ist geschlossen, solange kein WLAN zugeordnet ist.')
        this.db.updateRestaurant(this.db.currentRestaurantKey)
        return
      }
      */
      
      if (!this.db.currentRestaurant.tables || this.db.currentRestaurant.tables.length <= 0) {
        this.db.currentRestaurant.isOpen = false;
        this.util.presentToast('Lokal ist geschlossen, solange keine Tische erstellt wurden.')
        this.db.updateRestaurant(this.db.currentRestaurantKey)
        return
      }
    }

    if (!this.db.currentRestaurant.isOpen && (this.db.currentRestaurant.orderMode['delivery'] == true || this.db.currentRestaurant.orderMode['take-away'] == true) && this.db.currentRestaurant.paymentIsMandatory && (!this.db.currentRestaurant.paypal || this.db.currentRestaurant.paypal == '')) {
      this.util.presentToast('Lokal ist geschlossen, solange keine Paypal Mail-Adresse zugeordnet ist (wird für die Abholung / Bringdienst benötigt) oder erlaube "Zahlung bei Abholung/Lieferung" in den Einstellungen.')
      return
    }

    if (!this.db.currentRestaurant.isOpen && (this.db.currentRestaurant.orderMode['delivery'] == true || this.db.currentRestaurant.orderMode['take-away'] == true) && (!this.db.currentRestaurant.paypal || this.db.currentRestaurant.paypal == '')) {
      this.util.presentToast('Achtung: Sie haben keine Paypal Adresse hinterlegt. Daher kann der Kunde bei Abholung / Lieferung erst bei zubereitetem Gericht zahlen.', 6000)
    }

    if (this.db.currentRestaurant.isOpen === true) {
      this.ask2CloseRestaurant()
    } else {
      //Oeffnet gerade
      this.db.currentRestaurant.isOpen = !this.db.currentRestaurant.isOpen
      this.db.updateRestaurant(this.db.currentRestaurantKey)

      this.pushService.sendPush(this.db.currentRestaurant.name + " öffnet gerade", "Schau doch heute mal vorbei", TOPIC_RESTAURANT_IS_OPENING, 'BARELA', this.db.currentRestaurantKey).subscribe((val: any) => {
        this.util.log("Nachricht gesendet - Return Code: ", val)
      })
    }
  }

  ask2CloseRestaurant() {
    this.alert.presentAlertConfirm(this, (ref) => { ref.closeRestaurant() }, 'Lokal jetzt schliessen?', 'Ihre Gäste können nur in einem geöffneten Lokal bestellen.')
  }

  closeRestaurant() {
    this.db.currentRestaurant.isOpen = !this.db.currentRestaurant.isOpen
    this.db.updateRestaurant(this.db.currentRestaurantKey)

    if (this.hasStillOrdersOpened()) {
      this.ask2CloseAllOrders();
    }
  }

  //**************************************** */
  //      ORDERS                             */
  //**************************************** */


  hasStillOrdersOpened(): boolean {
    for (let orderGroupKey of Object.keys(this.db.currentOrderGroupsAll)) {
      for (let order of this.db.currentOrderGroupsAll[orderGroupKey].order) {
        if (order.state != 2) return true
      }
    }

    return false;
  }

  ask2CloseAllOrders() {
    this.alert.presentAlertConfirm(this, (ref) => { ref.closeAllOpenOrders() }, 'Offene Bestellungen gefunden', 'Sollen alle offenen Bestellungen geschlossen werden?')
  }

  closeAllOpenOrders() {
    for (let orderGroupKey of Object.keys(this.db.currentOrderGroupsAll)) {
      for (let order of this.db.currentOrderGroupsAll[orderGroupKey].order) {
        if (order.state != 2) {
          order.state = 2
          this.db.updateOrderGroup(this.db.currentOrderGroupsAll[orderGroupKey])
        }
      }
    }

    return false;
  }

}
