//import {ImageGalleryComponent} from '../components/image-gallery/image-gallery.component';
import {HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    IonicModule.forRoot()
  ],
  providers: [
    //BarcodeScanner
  ],
  exports: [],
  declarations: []
})
export class SharedModule {}
