import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AuthService } from 'src/app/services/auth.service';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { Vibration } from '@ionic-native/vibration/ngx';
import { HttpClientModule } from '@angular/common/http';
import { Camera } from '@ionic-native/camera/ngx';
import { AngularFireStorage } from 'angularfire2/storage';
import { RestaurantListPageModule } from 'src/app/pages/shared/restaurant-list/restaurant-list.module';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { QRScanInfoComponent } from 'src/app/components/qrscan-info/qrscan-info.component';
import { SettingsProvider } from 'src/app/services/settings.service';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { SharedModule } from 'src/app/pages/shared.module';
import { NFC, Ndef } from '@ionic-native/nfc/ngx';
import { BatteryStatus } from '@ionic-native/battery-status/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { PermissionDescrComponent } from 'src/app/components/permission-descr/permission-descr.component';
import { IonicGestureConfig } from './services/ionic-gesture-config';
import { WifiWizard2 } from '@ionic-native/wifi-wizard-2/ngx';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { SortExtraOptionPipe } from 'src/app/services/sort-extra-option.pipe';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [AppComponent, PopoverComponent, QRScanInfoComponent, PermissionDescrComponent, SortExtraOptionPipe],
  entryComponents: [PopoverComponent, QRScanInfoComponent, PermissionDescrComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    // IonicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    IonicModule.forRoot({
      mode: 'md',
      //modalEnter: 'modal-slide-in',
      // modalLeave: 'modal-slide-out',
      // prodMode: true,
      // pageTransition: 'ios',
      // preloadModules: true
    }),
    SharedModule,
    RestaurantListPageModule,
    AppRoutingModule,
    FormsModule,
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFireDatabase,
    AuthService,
    AngularFireStorage,
    Camera, SettingsProvider, BatteryStatus,WifiWizard2,
    Vibration, Diagnostic, QRScanner, Insomnia, NFC, Ndef,
    FirebaseAuthentication,
    {
        provide: HAMMER_GESTURE_CONFIG,
        useClass: IonicGestureConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
