
import { OpeningTime } from './opening-time.model';
import { Table } from 'src/app/models/table.model';
import { Bonuskarte } from 'src/app/models/bonuskarte.model';
import { Special } from './special.model';
import { DeliveryArea } from 'src/app/models/delivery-area.model';

const OPEN: number = -1
const NOT_PROOFED: number = -2
const IN_CHECKING: number = 0
const APPROOVED: number = 99

export class Restaurant {
    public isOpen: boolean = false;

    public name: string = "Unbekanntes Lokal";

    public description: string = "Hier fehlt die Beschreibung des Lokals...";

    public enableOrders: boolean = true;//deprecated???

    public isActive: boolean = true;

    public currency: string = 'EUR';

    public showPhoneNumber: boolean = false;

    public enableOpeningReminder: boolean = false;

    public showDescription: boolean = false;

    public hasReservations: boolean = false;

    public guestRegistration: boolean = false;

    public opensAutomatically: boolean = true;

    public phoneValidation: boolean = true;

    public sendOrdersViaMail: boolean = false;

    public preOrder: boolean = false;

    public confirmOrderAutomatically: boolean = false;

    public url: string;

    public menuKey: string;

    public reservationListKey: string;

    public mail: string;

    public paypal: string;

    public inhaber: string;

    public address: string;

    public phone: string;

    public location;

    public isApprovedByMamingo: number = OPEN;

    public employeeess: string[] = []

    public maxTables: number = 1;

    public bonuskarten: Bonuskarte[] = []

    public clientMustJoinBonuskarte: boolean = false;

    public wifi: any = { ssid: null, bssid: null } //depricated
    public wifi2: any[] = []

    public orderMode: any = { "eat-here": false, "take-away": true, "delivery": false }

    public special: any = { title: "", text: "", img: null } //depricated
    public special2: Special[] = []

    public oeffnungszeiten: OpeningTime[] = []

    public tables: Table[] = []

    public deliveryAreas: DeliveryArea[] = []

    public options: any = {}

    public masterOwner: string = '';

    public paymentIsMandatory: boolean = true

    public defaultPreperationTime: number = 30;

    public minPreorderMinutesBeforeStart: number = 30;

    public owners: string[] = []

    public employees: string[] = []

    public constructor(public id: string) {
        this.oeffnungszeiten = OpeningTime.initDefaultZeiten();

        this.address = ''
    }






}
