import { PopoverController, ModalController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { Events } from 'src/app/services/events.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public constructor(private alertCtrl: AlertController) {

  }


  async presentAlertConfirm(ref, success, title = "Bestätigung", text = "", cancelText = "Nein", agreeText = "Ja") {
    const alert = await this.alertCtrl.create({
      header: title,
      message: text,
      buttons: [{
        text: cancelText, role: 'cancel', cssClass: 'alert-button alert-yes-no-bt', handler: (blah) => { console.debug("Not Confirmed") }
      }, {
        text: agreeText,
        cssClass: 'alert-button alert-yes-no-bt',
        handler: () => {
          success(ref)
        }
      }
      ]
    });

    await alert.present();
  }


  promptType: any = 'text'
  async presentAlertPrompt(ref, success, title = "Bestätigung", subheader = "", value = "", placeholder = "", cancelText = "Abbrechen", agreeText = "Speichern") {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: subheader,
      inputs: [{ name: 'input', type: this.promptType, id: 'input2', value: value, placeholder: placeholder }],
      buttons: [{
        text: cancelText, role: 'cancel', cssClass: 'secondary', handler: () => { console.log('Confirm Cancel'); }
      }, {
        text: agreeText,
        handler: (data) => {
          if (data.input.length > 0 && data.input.length < 150)
            success(ref, data)
        }
      }
      ]
    });
    return alert.present().then(() => {
      const firstInput: any = document.querySelector('ion-alert input');
      if (firstInput) firstInput.focus();
      return;
    });
  }

  async presentMultipleAlertPrompt(ref, success, inputs, title = "Bestätigung", subheader = "", value = "", placeholder = "", cancelText = "Abbrechen", agreeText = "Speichern") {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: subheader,
      inputs: inputs,
      buttons: [{
        text: cancelText, role: 'cancel', cssClass: 'secondary', handler: () => { console.log('Confirm Cancel'); }
      }, {
        text: agreeText,
        handler: (data) => {
          success(ref, data)
        }
      }
      ]
    });

    return alert.present().then(() => {
      const firstInput: any = document.querySelector('ion-alert input');
      if (firstInput) firstInput.focus();
      return;
    });
  }
  //<preference name="KeyboardDisplayRequiresUserAction" value="false" />

  createCheckInput(name, label, value, currentVal, checked = false, enabled = true, type = 'radio') {
    return {
      name: name,
      type: type,
      label: label,
      value: value,
      disabled: !enabled,
      checked: checked !== undefined ? checked : value === currentVal
    }
  }

  async presentAlertCheck(ref, success, inputData: any[], title = "Bestätigung", text = "", cancelText = "Abbrechen", agreeText = "OK") {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: text,
      inputs: inputData,
      buttons: [
        { text: cancelText, role: 'cancel', cssClass: 'secondary' }, {
          text: agreeText,
          cssClass: "radio",
          handler: (data) => {
            success(ref, data)
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertInfo(text = "", title = "Information") {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: text,
      buttons: [{
        text: "OK"
      }]
    });
    alert.present()
  }



}