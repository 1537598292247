import {UtilService} from '../../services/util.service';
import { DBService } from '../../services/db.service';
import { ModalController } from '@ionic/angular';
import { NgZone, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-permission-descr',
  templateUrl: './permission-descr.component.html',
  styleUrls: ['./permission-descr.component.scss'],
})
export class PermissionDescrComponent implements OnInit {

  constructor(private db: DBService, private ngZone: NgZone, private router: Router, private util:UtilService, private modalCtrl:ModalController) { }
  
  ngOnInit() {
    this.util.isModalOpen = true
  }

  dismiss(data){
    this.modalCtrl.dismiss(data);
  }


  back() {
    if (!this.db.currentRestaurantKey) {
      this.ngZone.run(() => this.router.navigate(['/restaurant-list']))

      return
    }
    if (!this.db.loggedUserUID || !(this.db.isOwner || this.db.isEmployee)) this.ngZone.run(() => this.router.navigate(['/customer-home']))
    else this.ngZone.run(() => this.router.navigate(['/owner-home']))
  }

}
