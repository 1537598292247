// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCH-0hSyVb-iFjAZtpjS8pnVRi_S_BPubI",
    authDomain: "barela-ca314.firebaseapp.com",
    databaseURL: "https://barela-ca314.firebaseio.com",
    projectId: "barela-ca314",
    storageBucket: "barela-ca314.appspot.com",
    messagingSenderId: "831383998623",
    appId: "1:831383998623:web:6402a33c5bf8230c",
    vapidKey: 'BDjqu0BNG5Zw7rgN0eZ-BDbQX9DpkMJhV4ifucfHHagLRA1A8e2qcWruCwkSxbCMuPKPiUwp4d9V7WhsH_Pj8I8'
  }
};

//Test DB
export const environment2 = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCzLLUf43OtkUNIxtanA2BfIboLMHcd6ao",
    authDomain: "barelatest.firebaseapp.com",
    databaseURL: "https://barelatest.firebaseio.com",
    projectId: "barelatest",
    storageBucket: "",
    messagingSenderId: "661715881202",
    appId: "1:661715881202:web:2a0e4bbb77fcf6b9"
  }
};





export const STORAGE_LAST_RESTAURANT:string = "lastRestaurant"
export const STORAGE_MY_ORDERS:string = "myOrders"
export const STORAGE_PUSH_SUBSCRIPTIONS:string = "myPushSubscriptions"
export const STORAGE_MY_BASKET:string = "myBasket"
export const STORAGE_CACHE_BASKET:string = "myCachedBasket"
export const STORAGE_PERSONAL_DATA:string = "myPersonalData"
export const STORAGE_ALL_RESTAURANTS:string = "allRestaurantsMap"
export const STORAGE_ALL_RESTAURANTS_ICON_MAP:string = "allRestaurantsIconMap"
export const STORAGE_ALL_RESTAURANTS_ICONSIZE_MAP:string = "allRestaurantsIconSizeMap"
export const STORAGE_ALL_MENUS:string = "allMenusMap"
export const STORAGE_ALL_RESERVATIONS:string = "allReservationsMap"
export const STORAGE_LAST_TIME_OWNER:string = "lastTimeOwner"
export const STORAGE_EMPLOYEE_MODE:string = "storageEmployeeMode"



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
