import { Injectable, ElementRef } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  elGoogleMapCanvas: ElementRef;

  furtherLocalsEnabled: boolean = false
  favEnabled: boolean = true

  constructor(private util: UtilService) { }

  setMapEnabled(val: boolean) {
    this.furtherLocalsEnabled = true
  }

  setFavEnabled(val: boolean) {
    this.favEnabled = val
  }

}
