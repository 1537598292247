import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'restaurant-list', pathMatch: 'full' },
//  { path: ':key', redirectTo: 'restaurant-list', pathMatch: 'full' },
  { path: 'restaurant-list', loadChildren: () => import('./pages/shared/restaurant-list/restaurant-list.module').then( m => m.RestaurantListPageModule)},
  { path: 'menu', loadChildren: () => import('./pages/shared/menu/menu.module').then( m => m.MenuPageModule)},
  { path: 'order', loadChildren: () => import('./pages/shared/order/order.module').then( m => m.OrderPageModule)},
  { path: 'about/:view', loadChildren: () => import('./pages/shared/about/about.module').then( m => m.AboutPageModule)},
  { path: 'about', loadChildren: () => import('./pages/shared/about/about.module').then( m => m.AboutPageModule)},
  { path: 'opening-time', loadChildren: () => import('./pages/shared/opening-time/opening-time.module').then( m => m.OpeningTimePageModule)},
  { path: 'local-info', loadChildren: () => import('./pages/shared/local-info/local-info.module').then( m => m.LocalInfoPageModule)},
  { path: 'contact', loadChildren: () => import('./pages/shared/contact/contact.module').then( m => m.ContactPageModule)},
  { path: 'app-entry', loadChildren: () => import('./pages/shared/app-entry/app-entry.module').then( m => m.AppEntryPageModule)},
  { path: 'reservation-customer', loadChildren: () => import('./pages/customer/reservation-customer/reservation-customer.module').then( m => m.ReservationCustomerPageModule)},
  { path: 'register/:view', loadChildren: () => import('./pages/customer/register/register.module').then( m => m.RegisterPageModule)},
  { path: 'basket', loadChildren: () => import('./pages/customer/basket/basket.module').then( m => m.BasketPageModule)},
  { path: 'customer-home', loadChildren: () => import('./pages/customer/customer-home/customer-home.module').then( m => m.CustomerHomePageModule)},
  { path: 'bonuskarte-customer', loadChildren: () => import('./pages/customer/bonuskarte-customer/bonuskarte-customer.module').then( m => m.BonuskarteCustomerPageModule)},
  { path: 'owner-home', loadChildren: () => import('./pages/owner/owner-home/owner-home.module').then( m => m.OwnerHomePageModule)},
  { path: 'edit-special', loadChildren: () => import('./pages/owner/edit-special/edit-special.module').then( m => m.EditSpecialPageModule)},
  { path: 'edit-table', loadChildren: () => import('./pages/owner/edit-table/edit-table.module').then( m => m.EditTablePageModule)},
  { path: 'bonsukarte-owner', loadChildren: () => import('./pages/owner/bonsukarte-owner/bonsukarte-owner.module').then( m => m.BonsukarteOwnerPageModule)},
  { path: 'reservation-owner', loadChildren: () => import('./pages/owner/reservation-owner/reservation-owner.module').then( m => m.ReservationOwnerPageModule)},
  {
    path: 'eat-outside',
    loadChildren: () => import('./pages/customer/eat-outside/eat-outside.module').then( m => m.EatOutsidePageModule)
  },
  {
    path: 'edit-delivery-area',
    loadChildren: () => import('./pages/owner/edit-delivery-area/edit-delivery-area.module').then( m => m.EditDeliveryAreaPageModule)
  },
  {
    path: 'corona',
    loadChildren: () => import('./pages/shared/corona/corona.module').then( m => m.CoronaPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/shared/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
