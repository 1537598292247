import { STORAGE_LAST_RESTAURANT, STORAGE_EMPLOYEE_MODE } from '../environments/environment.prod';
import { UtilService } from './services/util.service';
import { DBService } from './services/db.service';
import { Component, NgZone } from '@angular/core';

import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { STORAGE_MY_ORDERS, STORAGE_ALL_RESTAURANTS_ICON_MAP, STORAGE_ALL_RESTAURANTS_ICONSIZE_MAP } from '../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ImageService } from './services/image.service';
import { SettingsProvider } from './services/settings.service';
import { STORAGE_COLOR_THEME, STORAGE_LAST_ROLE, STORAGE_FAV_RESTAURANTS } from '../environments/constant';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { UserService } from './services/user.service';
import { STORAGE_MY_RESERVATIONS, ANALYTICS_EV_DIRECT_ENTRY, STORAGE_PUSH_ANALYTICS } from '../environments/constant'
import { Events } from 'src/app/services/events.service';
import { WifiService } from 'src/app/services/wifi.service';
import { BonuskarteService } from 'src/app/services/bonuskarte.service';
import { DisplayService } from 'src/app/services/display.service';
import { CoronaService } from 'src/app/pages/shared/corona/corona.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  selectedTheme: String;

  public appPages = [
    {
      title: 'Übersicht aller Lokale',
      url: '/restaurant-list',
      //  icon: 'home'
    },
    {
      title: 'Über Barela',
      url: '/about'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router, public util: UtilService,
    public authService: AuthService, private ngZone: NgZone, private userService: UserService,
    public db: DBService, private imageService: ImageService, private qrScanner: QRScanner,
    private alertCtrl: AlertController, private events: Events, private settings: SettingsProvider,
    public wifiService: WifiService, public bonuskartenService: BonuskarteService, private displayService: DisplayService,
    private route: ActivatedRoute, private coronaService: CoronaService
  ) {
    this.settings.getActiveTheme().subscribe(val => this.selectedTheme = val);

    this.initializeApp();

    this.events.subscribe('navToRoot', () => {
      this.util.warn("Event navToRoot wurde ausgelöst")

      if (this.db.getRestaurantKeys('OWNER').length === 1) {
        this.db.selectRestaurant(this.db.getRestaurantKeys('OWNER')[0])

        if (this.db.loggedUserUID && (this.db.isOwner || this.db.isEmployee)) {
          this.ngZone.run(() => this.router.navigate(['/owner-home']))
        } else {
          this.ngZone.run(() => this.router.navigate(['/customer-home']))
        }
      } else {
        //Event um zurueck zu navigieren
        this.ngZone.run(() => this.router.navigate(['/restaurant-list']))
      }
    });
  }

  location = window.location.href
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // this.db.exportSpeisekarte()
      this.db.startAnalytics()

      let key = localStorage.getItem(STORAGE_EMPLOYEE_MODE)
      if (key) {
        this.util.employeeMode = JSON.parse(key)
      } else {
        this.util.employeeMode = false
      }
      
      key = localStorage.getItem(STORAGE_FAV_RESTAURANTS)
      if (key) {
        this.userService.favouriteRestaurants = JSON.parse(key)
      } else {
        this.userService.favouriteRestaurants = []
      }

      this.loadLastTheme()

      this.userService.loadBonuscards();

      this.events.subscribe('restaurantsLoadedFromStorage', () => {
        this.loadRestaurantsIconsFromLocalStorage();
        //this.openLastRestaurant() //Todo naechstes mal...
      });

      this.events.subscribe('restaurantsLoaded', () => {
        //Event um zurueck zu navigieren
        for (let key of Object.keys(this.db.restaurants)) {
          this.imageService.loadRestaurantImageBasedOnMetadataComparison(key, this.imageService.restaurantImgSizeMap[key]);
        }
        this.openStartScreen()
        
      });
      this.db.loadFromStorage();
      
      this.initBackButton();

      this.authService.user.subscribe((res: any) => {
        if (res && res.uid && res.email) this.db.loggedUserUID = res.uid;
      })

      this.db.loadRestaurants()

      this.loadMyPrivateOrdersFromLocalStorage();

      this.loadMyPrivateReservationsFromLocalStorage();

      this.initAnalyticsPushSubscription();

      // Temp. disabled since people were not able to see buttons in footer
      if(this.util.randomNumber < -0.3 && this.util.isMobileWeb) this.util.presentAppToast();
    });
  }

  initAnalyticsPushSubscription() {
    let val:any = localStorage.getItem(STORAGE_PUSH_ANALYTICS)
    if (val) {
      this.userService.pushAnalytics = JSON.parse(val)
    } else {
      this.userService.pushAnalytics = false;
    }
  }

  openCorona(){
    this.coronaService.selectRestaurant()
    this.ngZone.run(() => this.router.navigate(['/corona']))    
  }
  
  selectRestaurant(key: string): boolean {
    if(!this.db.restaurants[key]) return false
    this.db.selectRestaurant(key)
    if ((this.db.loggedUserUID && (this.db.isOwner || this.db.isEmployee)) || this.util.isAdmin) {
     this.router.navigate(['/owner-home'])
    } else {
      this.router.navigate(['/customer-home'])
    }
    return true
  }

  private openStartScreen() {
    let res = localStorage.getItem(STORAGE_LAST_ROLE)
    //Falls der Startbildschirm noch nie gezeigt wurde, anzeigen lassen...
    // if (!res)
    //   this.ngZone.run(() => this.router.navigate(['/app-entry']))
    // else{

    var url = new URL(this.location);

    if (url.searchParams.has("restaurant")) {
      //load restaurant directly, e.g.: http://localhost:8100?restaurant=-MRdyjwYZW1lszCmVPRr&source=1
      if(url.searchParams.has("source")) this.util.sourceParam = url.searchParams.get("source");
      if(url.searchParams.has("target")) this.util.targetParam = url.searchParams.get("target");

      if(this.selectRestaurant(url.searchParams.get("restaurant"))){
        this.db.addAnalytics(ANALYTICS_EV_DIRECT_ENTRY)
        return
      }
    }

    //if(window.location.href.indexOf("-home") == -1 && window.location.href.indexOf("restaurant-list") == -1 && window.location.href.indexOf("register") == -1 && !this.db.loggedUserUID) //wenn User nicht bereits weiter geklickt hat
     // this.ngZone.run(() => this.router.navigate(['/restaurant-list'])) //hier tmp for testing kann auch app-entry stehen
    //  }
  }

  //*** Last color theme is always stored in local storage ***
  private loadLastTheme() {
    let res = localStorage.getItem(STORAGE_COLOR_THEME)
    if (res) {
      this.settings.setActiveTheme(JSON.parse(res))
    }
  }

  /**
   * Switch between light and dark theme.
   */
  toggleAppTheme() {
    if (this.selectedTheme === 'dark-theme') {
      this.settings.setActiveTheme('light-theme');
      localStorage.setItem(STORAGE_COLOR_THEME, JSON.stringify('light-theme'))
    } else {
      this.settings.setActiveTheme('dark-theme');
      localStorage.setItem(STORAGE_COLOR_THEME, JSON.stringify('dark-theme'));
    }
  }

  /**
   * Loads restaurant images Base64 from local storage including
   * metadata to compare with server images (for updating purposes).
   */
  loadRestaurantsIconsFromLocalStorage() {
    let val = localStorage.getItem(STORAGE_ALL_RESTAURANTS_ICON_MAP)
    if (val) {
      this.imageService.restaurantImgMap = JSON.parse(val)
    }

    val = localStorage.getItem(STORAGE_ALL_RESTAURANTS_ICONSIZE_MAP)
    if (val) {
      this.imageService.restaurantImgSizeMap = JSON.parse(val)
    }
  }

  loadMyPrivateOrdersFromLocalStorage() {
    let val = localStorage.getItem(STORAGE_MY_ORDERS)
    if (val) {
      this.util.log("Alle privaten Orders geladen", val)
      this.db.myPrivateOrders = JSON.parse(val);
      // All orders are required from local storage for the "Bonuskarte". Thats why, 
      // the code for loading only last 24h is disabled:
      /* 
      let now:number = new Date().getTime();
       this.db.myPrivateOrders = val.filter(orderGroup => {
         return (now < (orderGroup.timestamp+86400000));
       });
      */

      for (let privateOrder of this.db.myPrivateOrders) {
        // Subscribe for changes for our local orders
        this.db.subscribeForLocalOrders(privateOrder)
      }
    }
  }

  loadMyPrivateReservationsFromLocalStorage() {
    let val = localStorage.getItem(STORAGE_MY_RESERVATIONS)
    if (val) {
      let reservations = JSON.parse(val)
      this.util.log("Alle privaten Reservationen geladen", reservations)

      let now: number = new Date().getTime();
      this.db.myPrivateReservations = reservations.filter(reservation => {
        return (now < (reservation.date + 86400000));
      });

      for (let privateReservation of this.db.myPrivateReservations) {
        // Subscribe for changes for our local orders
        this.db.subscribeForLocalReservations(privateReservation)
      }
    }
  }


  /**
   * Method is called, whenever the Hardware Back Button is clicked.
   */
  back(currentPage: string) {
    this.util.info("BACK_BT: Test, ob ein Popup geöffnet ist", this.util.isPopupOpen)
    if (this.util.isPopupOpen) { //1. Popup schliessen
      this.util.isPopupOpen = false;
      this.util.popoverController.dismiss();
      this.events.unsubscribe('sureForYorOder-' + this.util.timeWhenSureForYorOder)
      this.events.unsubscribe('sureCallingService-' + this.util.timeWhenSureCallingService)

      this.events.unsubscribe('addToBasketPopoverDismissed-' + this.util.timeWhenAs2AddBasket)
      this.events.unsubscribe('sureToDelete-' + this.util.timeWhenSureToDelete)
      this.events.unsubscribe('askForPermissions-' + this.util.timeWhenAskForPermissions)
      return
    }

    this.util.info("BACK_BT: Test, ob ein Modal geöffnet ist", this.util.isModalOpen)
    if (this.util.isModalOpen) {
      this.util.isModalOpen = false


      this.qrScanner.disableLight()
      this.qrScanner.hide()
      this.qrScanner.destroy()
      this.settings.setActiveTheme(this.settings.lastActive);
      if (this.util.scanSub) {
        this.util.scanSub.unsubscribe()
      }

      this.util.modalController.dismiss()
      return
    }

    this.util.info("BACK_BT: Test, ob man sich in order, basket, andere Seiten, ... befindet", (this.router.url === '/order' || this.router.url === '/basket'))
    if (this.router.url === '/order' || this.router.url === '/basket' || this.router.url === '/opening-time' ||
      this.router.url === '/edit-special' || this.router.url === '/local-info' || this.router.url === '/edit-table' || this.router.url === '/contact' || this.router.url === '/edit-delivery-area' 
      || this.router.url === '/bonuskarte-customer' || this.router.url === '/bonsukarte-owner' || this.router.url === '/reservation-owner' || this.router.url === '/reservation-customer') {

      this.db.setPage('home')

      if (this.db.loggedUserUID && (this.db.isOwner || this.db.isEmployee)) {
        this.ngZone.run(() => this.router.navigate(['/owner-home']))
      } else {
        this.ngZone.run(() => this.router.navigate(['/customer-home']))
      }
      return
    }

    this.util.info("BACK_BT: Test, ob man sich in about befindet", (this.router.url === '/about'))
    if (this.router.url === '/about') {
      this.db.setPage('home')
      this.ngZone.run(() => this.router.navigate(['/restaurant-list']))
      return
    }

    if (this.router.url === '/menu') {
      this.events.publish('backButtonInMenu')
      return
    }

    if (this.router.url === '/eat-outside') {
      this.events.publish('backButtonInPaypal')
      return
    }

    this.util.info("BACK_BT: Switch Current Page", currentPage)
    switch (currentPage) {
      case 'home':
        localStorage.removeItem(STORAGE_LAST_RESTAURANT)
        this.ngZone.run(() => this.router.navigate(['/restaurant-list']))
        break;
      case 'order':
      case 'wifi':
      case 'employees'://TODO gibts noch nicht
        this.db.setPage('home')
        if (this.db.loggedUserUID && (this.db.isOwner || this.db.isEmployee)) {
          this.util.info("BACK_BT: Ist in Unterseiten von Hauptseite als Owner")
          this.ngZone.run(() => this.router.navigate(['/owner-home']))//.then();
        } else {
          this.util.info("BACK_BT: Ist in Unterseiten von Hauptseite als Gast")
          //this.router.navigate(['/customer-home'])
          this.ngZone.run(() => this.router.navigate(['/customer-home']))//.then();
        }
        return;
      case 'basket':
        this.db.setPage('home')
        this.ngZone.run(() => this.router.navigate(['/customer-home']))
        break;
      default:
        localStorage.removeItem(STORAGE_LAST_RESTAURANT)
        this.ngZone.run(() => this.router.navigate(['/restaurant-list']))
    }
  }

  initBackButton() {
    this.platform.backButton.subscribeWithPriority(9999999, () => {
      this.util.log("BACK_BT: initBackButton ausgelöst; Router URL und Current Page:", this.router.url, this.db.currentPage)
      if (this.router.url !== '/restaurant-list') {
        this.back(this.db.currentPage);
        return;
      } else {
        navigator['app'].exitApp()
        return;
      }
      /*
     } else {
        this.generic.showAlert("Exit", "Do you want to exit the app?", this.onYesHandler, this.onNoHandler, "backPress");
        navigator['app'].exitApp()
        return;
      }*/
    });
  }

  async setEmployeeMode(state: boolean, redirect: boolean = false) {
    let myRef = this
    if (state === false) {
      const alert = await this.alertCtrl.create({
        header: 'Als Besitzer anmelden',
        message: 'Bitte gib das Passwort des Besitzer Accounts ein.',
        cssClass: 'myAlert',
        inputs: [
          {
            name: 'password',
            type: 'password',
            value: ""
          }
        ],
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            cssClass: 'alert-button',
            handler: () => {
              myRef.util.log('Confirm Cancel');
            }
          }, {
            text: 'Anmelden',
            cssClass: 'alert-button',
            handler: (data) => {
              if (data.password) {
                myRef.loginAsOwner(data.password, state, redirect)
              } else {
                myRef.util.presentToast("Leider konnten wir dich nicht identifizieren.", 3000, "toast-warning")
              }
            }
          }
        ]
      });
      await alert.present();
    }

    else {
      this.util.employeeMode = state
      localStorage.setItem(STORAGE_EMPLOYEE_MODE, JSON.stringify(this.util.employeeMode))
      if (redirect) {
        this.ngZone.run(() => this.router.navigate(['/restaurant-list']))
      }
    }
  }

  loginAsOwner(pw: string, state: boolean, redirect: boolean = false) {
    let myRef = this
    this.authService.reauthanticate(pw).then(function () {
      myRef.util.employeeMode = state
      localStorage.setItem(STORAGE_EMPLOYEE_MODE, JSON.stringify(myRef.util.employeeMode))

      if (redirect) {
        myRef.ngZone.run(() => myRef.router.navigate(['/restaurant-list']))
      }
      myRef.util.presentToast("Du bist jetzt als Besitzer angemeldet.", 3000, "toast-success")
    }).catch(function (error) {
      myRef.util.error("4093", error)
      myRef.util.presentToast("(Code 4043) Es ist etwas schief gelaufen: " + error, 3000, "toast-warning")
    });
  }

  openLastRestaurant() {
    this.util.log("Open last restaurant")
    let key = localStorage.getItem(STORAGE_LAST_RESTAURANT)
    if (key) {
      this.db.selectRestaurant(JSON.parse(key))

      if (this.db.loggedUserUID) {
        this.ngZone.run(() => this.router.navigate(['/owner-home']))
      } else {
        this.ngZone.run(() => this.router.navigate(['/customer-home']))
      }
    }
  }

  register(view: string) {
    this.ngZone.run(() => this.router.navigate(['/register/' + view]))
  }

  logout() {
    // this.ngZone.run(() => this.router.navigate(['/restaurant-list']))
    this.authService.logout() //navigation is now here...
    this.util.employeeMode = false
    this.util.popup('green', 'Erfolgreich abgemeldet!')
  }

  simulateHardwareBackBt(){
    this.back(this.db.currentPage)

  }

}
