import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { LoadingController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';
import { DBService } from 'src/app/services/db.service';
import { THREE_MINS } from 'src/environments/constant';
import { Events } from 'src/app/services/events.service';
import { WifiWizard2 } from '@ionic-native/wifi-wizard-2/ngx';


@Injectable({
  providedIn: 'root'
})
export class WifiService {


  lastWifiScanTS: number = -1
  previouslySelectedNetworkExpired; scanResultsInterval; networks = []

  constructor(public diagnostic: Diagnostic, public locationAccuracy: LocationAccuracy, private events: Events, public util: UtilService, private db: DBService, private loadingController: LoadingController,
    private wifiWizard2: WifiWizard2) { }

  public isInRestaurant(wifis: any[]): boolean {
    this.util.log("Aktuelle Netzwerke", this.networks)
    if (!this.allPreconditionsFullfilled()) return false

    for (let network of this.networks) {
      for (let myWifi of wifis) {
        if (network.BSSID === myWifi.bssid) return true;

        console.log(network.BSSID.substring(0, 9), myWifi.bssid.substring(0, 9), network.BSSID.substring(0, 9) === myWifi.bssid.substring(0, 9))
        console.log(network.SSID === myWifi.ssid, myWifi.ssid, network.SSID)
        if (network.SSID === myWifi.ssid && network.BSSID.length > 10 && myWifi.bssid.length > 10 && network.BSSID.substring(0, 9) === myWifi.bssid.substring(0, 9)) {
          return true
        }
      }
    }

    return false;
  }

  public isBSSIDInRestaurant(bssid: any, wifis: any[]): boolean {
    for (let myWifi of wifis) {
      if (myWifi.bssid === bssid) return true;
      if (myWifi.bssid.length > 10 && bssid.length > 10 && myWifi.bssid.substring(0, 9) === bssid.substring(0, 9)) {
        return true
      }
    }

    return false;
  }

  newScanRequired(): boolean {
    return new Date().getTime() > (this.lastWifiScanTS + THREE_MINS)
  }

  public allPreconditionsFullfilled(): boolean {
    return (this.networks && this.networks.length > 0);
  }

  public enableLocalLocationAppPermission() {
    let myReference = this

    this.diagnostic.getLocationAuthorizationStatus().then((status) => {
      myReference.util.log("Diagnostic getLocationAuthorizationStatus: " + status)
    })

    this.diagnostic.isLocationAuthorized().then((authorized) => {
      if (authorized) {
        myReference.util.log("App has location authorization")
        //********************************************* */
        //*********          WIFI           *********** */
        //********************************************* */
        myReference.enableWifi();
      }
      else {
        myReference.diagnostic.requestLocationAuthorization().then((status) => {
          //********************************************* */
          //*********          WIFI           *********** */
          //********************************************* */
          myReference.enableWifi();
          myReference.util.log("Auth access: " + status)
        })
      }
    })
  }

  private enableGPS() {
    let myRef = this
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      myRef.util.log("Can Request Location Accuracy?", canRequest)
      //if(canRequest) {
      // the accuracy option will be ignored by iOS
      myRef.locationAccuracy.request(myRef.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
        () => {
          myRef.util.log('Request successful')
          myRef.events.publish('allpermissionsgranted');
        },
        error => {
          myRef.util.log('Error requesting location permissions', error)
          myRef.util.presentToast('Fehler: Bitte aktiviere dein GPS und versuche es noch einmal.', 3000, 'toast-warning')
        }
      );
      //  }
    });

  }


  public enableWifi(inclGPS: boolean = true) {
    let _this = this
    this.wifiWizard2.isWifiEnabled().then(wifiIsEnabled => {
      console.log("isWifiEnabled?", wifiIsEnabled)
      if (!wifiIsEnabled) {
        _this.wifiWizard2.setWifiEnabled(true).then(wifiIsEnabled => {
          _this.util.log("WiFi was successfully turned on", wifiIsEnabled)
          if (inclGPS) _this.enableGPS()
        }, err => {
          _this.util.log("Error, while WiFi was turned on", err)
          //********************************************* */
          //*********          GPS            *********** */
          //********************************************* */
          if (inclGPS) _this.util.presentToast('Fehler: Wir konnten leider dein WLAN nicht aktivieren.', 3000, 'toast-warning')
        })
      } else {
        _this.util.log("Wifi was already enabled")
        //********************************************* */
        //*********          GPS            *********** */
        //********************************************* */
        if (inclGPS) _this.enableGPS()
      }
    })
  }


  anzahl: number = 0
  public initStartWifiScreening(resetList: boolean = true) {
    console.error("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx")
    console.error("xxxxx WIFI SCREENING xxxxx")
    console.error("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx")
    this.anzahl = 0
    let myRef = this
    this.wifiWizard2.isWifiEnabled().then(wifiIsEnabled => {
      if (!wifiIsEnabled) {
        myRef.util.log('Wifi is off');
        myRef.db.sendLog('err: wifi off in initStartWifiScreening')
      } else {
        myRef.db.sendLog('network scanning in initStartWifiScreening')
        myRef.db.sendLog('current networks: ' + JSON.stringify(myRef.networks))
        myRef.util.log("Scanning for networks...");
        if (resetList) myRef.networks = [];
        myRef.diagnostic.isLocationAuthorized().then((authorized) => {
          myRef.util.log("isLocationAuthorized...");
          if (authorized) {
            setTimeout(() => {
              myRef.saveNetworks()
            }, 1000
            );

          } else {
            //not authorized: TODO Jetzt schonzurueck
          }
        })
      }
    });
  }

  saveNetworks() {
    let myRef = this
    myRef.anzahl++;
    this.wifiWizard2.scan().then(availableNetworks => {
      myRef.db.sendLog('#' + myRef.anzahl + ':' + JSON.stringify(availableNetworks))
      console.error('#' + myRef.anzahl + ' availableNetworks', availableNetworks)
      if (availableNetworks && availableNetworks.length > 0) {
        if (!myRef.networks) myRef.networks = []
        for (let network of availableNetworks) {
          myRef.networks.push(network)
        }
        myRef.db.sendLog('Gesamt nach #' + myRef.anzahl + ':' + JSON.stringify(myRef.networks))
        console.error("geht")
        myRef.events.publish('networkdetected', 1)
      } else {
        myRef.db.sendLog('Err: Keine Netzwerke gefunden (' + myRef.anzahl + ')')
        if (myRef.anzahl > 4) {
          console.error("geht net")
          myRef.events.publish('networkdetected', -1)
        }
        else {
          setTimeout(() => {
            myRef.saveNetworks()
            return
          }, 3000)
        }
      }
      myRef.util.log(new Date().toLocaleString() + ": Updating network list with results.", availableNetworks)
    }, async err => {
      console.log("www", err)
      if (myRef.anzahl > 4) {
        console.error("geht net")
        myRef.events.publish('networkdetected', -1)
        return
      } else {
        await myRef.wifiWizard2.timeout(8000)
        myRef.saveNetworks()
      }

    })
  }


  public initStartWifiScreeningDeprecated(resetList: boolean = true) {
    let myRef = this
    this.wifiWizard2.isWifiEnabled().then(wifiIsEnabled => {
      if (!wifiIsEnabled) {
        myRef.util.log('Wifi is off');
      } else {
        myRef.wifiWizard2.listNetworks().then(previouslySelectedNetworks => {
        console.log("listNetworks", previouslySelectedNetworks)
        myRef.wifiWizard2.startScan().then(() => {
            myRef.util.log("Scanning for networks...");

            for (let i = 0; i < 4; i++) {

              setTimeout(() => {
                myRef.wifiWizard2.listNetworks().then(availableNetworks => {
                  if (availableNetworks && availableNetworks.length > 0) {
                    if (resetList) myRef.networks = availableNetworks;
                    else {
                      if (!myRef.networks) myRef.networks = []
                      for (let network of availableNetworks) {
                        myRef.networks.push(network)
                      }
                    }
                  }
                  myRef.util.log("Updating network list with results.", availableNetworks)
                }, function () {
                  myRef.util.log("Failed to get scan results.");
                });
              }, 1000);
            }
          }, function () {

            myRef.util.log('Failed to start WiFi scan.');
          });
        }, function () {
          myRef.util.log('Failed to get a listing of previously selected networks.');
        });
      }
    }, function () {
    });
  }







  //****************************************** */
  //IOS

  test1() {
    this.wifiWizard2.isWifiEnabled().then(wifiIsEnabled => {
      this.util.presentToast("isWifiEnabled: " + wifiIsEnabled, 6000)
    }, err => {
      this.util.presentToast("Error: " + err, 6000)
    })
  }

  //TODO   wenn angemeldet: order rand, abstand in order tabelle,neues regal ist nicht in auswahl vorhanden, wenn abgemeldet => 
  //karte ganz unten, push nachrichten,paypal allgemein, icons, auf HP, ios
  test2() {
    this.wifiWizard2.iOSConnectNetwork("FrankenthalerWifi", "HkGaIdWwH!3").then(val => {
      this.util.presentToast("ConnectNetwork: " + val, 6000)
    }, err => {
      this.util.presentToast("Error: " + err, 6000)
      console.log("xxx wrong password", err)
    })
  }

  test3() {
    this.wifiWizard2.getConnectedBSSID().then(val => {
      this.util.presentToast("getConnectedBSSID: " + val, 6000)
      console.log("getConnectedBSSID", val)
    }, err => {
      this.util.presentToast("Error: " + err, 6000)
      console.log("err getConnectedBSSID", err)
    })
  }

  test4() {
    this.wifiWizard2.iOSConnectNetwork("FrankenthalerWifids", "HkGaIdWwH!1").then(val => {
      this.util.presentToast("ConnectNetwork: " + val, 6000)
    }, err => {
      this.util.presentToast("Error: " + err, 6000)
      console.log("wrong network", err)
    })
  }

  test5() {
    this.wifiWizard2.iOSConnectNetwork("FrankenthalerWifi", "HkGaIdWwH!1").then(val => {
      console.log("iOSConnectNetwork", val)
      this.util.presentToast("ConnectNetwork: " + val, 6000)
    }, err => {
      console.log("error iOSConnectNetwork", err)
      this.util.presentToast("Error: " + err, 6000)
    })
  }



  getConnectedBSSID() {
    return this.wifiWizard2.getConnectedBSSID();
  }

  iOSConnectNetwork(ssid: string, pw: string) {
    return this.wifiWizard2.iOSConnectNetwork(ssid, pw)
  }

  connectToWifiMain() {
    if (this.util.isIOS()) {
      this.iOSconnect2WIFIs()
    } else {
      this.connectToWifi();
    }
  }

  iOSconnect2WIFIs() {
    let wifis: any[] = this.db.currentRestaurant.wifi2 ? this.db.currentRestaurant.wifi2 : []
    let foundWifi: any = undefined
    for (let wifi of wifis) {
      if (wifi.password && wifi.password.length > 0) {
        foundWifi = wifi
        break; //TODO: Hier nimmt er nur das erste was er findet
      }
    }

    this.util.debug('iOS Order iOSconnect2WIFIs in Service found wifi', foundWifi, wifis)
    if (foundWifi) {
      this.iOSConnectNetwork(foundWifi.ssid, foundWifi.password.length > 1 ? foundWifi.password : '').then(val => {
        this.util.debug('iOS Order iOSconnect2WIFIs in Service TRUE', val)
        this.util.presentToast("Erfolgreich verbunden", 3000, 'toast-success')
      }, err => {
        this.util.debug('iOS Order iOSconnect2WIFIs in Service ERR', err)
        this.util.presentToast('Leider ist das WLAN des Lokals nicht erreichbar.', 3500, 'toast-warning')
      })
    } else {
      this.util.presentToast('Es wurde kein WLAN des Lokals gefunden.', 3500, 'toast-warning')
    }

  }






















  //******************************************************************************************* */
  async connectToWifi() {
    let myReference = this
    console.log("connectToWifi")
    this.wifiWizard2.setWifiEnabled(true)

    let wifis: any[] = this.db.currentRestaurant.wifi2 ? this.db.currentRestaurant.wifi2 : []
    const loading = await this.loadingController.create({
      message: 'Verbinden...',
      duration: (wifis.length * 12000 + 6000) * 40000 //extra lang, sollte vorher dismissede werden
    });
    loading.present();

    this.wifiWizard2.isWifiEnabled().then(wifiIsEnabled => {

      if (!wifiIsEnabled) {
        this.wifiWizard2.setWifiEnabled(true).then(val => {
          myReference.util.log("WiFi was successfully turned on", wifiIsEnabled)

          //kurz zeit geben und erst dann verbinden nach dem es frisch an ist
          setTimeout(() => {
            myReference.realConnectoToWifi()
          }, 6000);
        }, function (err) {
          myReference.util.log("Error, while WiFi was turned on", err)
          this.loadingController.dismiss()
          myReference.util.presentToast('Fehler: Wir konnten leider dein WLAN nicht aktivieren.', 3000, 'toast-warning')
        });
      } else {
        myReference.util.log("Wifi was already enabled")

        //Check ob er bereits mit einem Netzwerk verbunden ist
        this.wifiWizard2.canPingWifiRouter().then(s => {
          myReference.util.log("getConnectedSSID exists", s)
          this.loadingController.dismiss()
          myReference.util.presentToast('Du bist bereits mit einem WLAN verbunden.')
        }, err => {
          myReference.util.log("getConnectedSSID not exists", err)
          //noch keine Verbindung#
          setTimeout(() => {
            myReference.realConnectoToWifi()
          }, 6000);
        })
      }
    })
  }



  private async realConnectoToWifi() {
    let wifis: any[] = this.db.currentRestaurant.wifi2 ? this.db.currentRestaurant.wifi2 : []

    setTimeout(() => {
      if (!connected) {
        this.loadingController.dismiss()
        myRef.util.presentToast('Leider hat das nicht geklappt.', 4000, 'toast-warning')
      }
    }, (wifis.length * 12000 + 6000));


    let connected: boolean = false
    let myRef = this


    for (let wifi of wifis) {
      if (wifi.password && wifi.password.length > 0) {
        console.log("Verbindungsversuch mit", wifi, wifi.capabilities.indexOf('WPA'))
        //WIFI, mit dem man sich verbinden kann / Leerzeichen, wenn kein Passwort uebertragen wird
        if (wifi.password.trim().length === 0) {
          //Offenes Wifi
          myRef.wifiWizard2.connect(wifi.ssid, true).then(s => {
            myRef.util.presentToast('Erfolgreich verbunden.', 4000, 'toast-success')
            connected = true
            this.loadingController.dismiss()
          }, err => {
            console.log("err", err)
          })
        } else if (wifi.capabilities.indexOf('WPA') > -1) {
          myRef.wifiWizard2.connect(wifi.ssid, true, wifi.password, "WPA").then(s => {
            myRef.util.presentToast('Erfolgreich verbunden.', 4000, 'toast-success')
            connected = true
            this.loadingController.dismiss()
          }, err => {
            console.log("err1", err)
          })
        } else {
          myRef.wifiWizard2.connect(wifi.ssid, true, wifi.password, "WEP").then(s => {
            myRef.util.presentToast('Erfolgreich verbunden.', 4000, 'toast-success')
            connected = true
            this.loadingController.dismiss()
          }, err => {
            console.log("err2", err)
          })
        }
      }
    }
  }
  //******************************************************************************************* */

}
