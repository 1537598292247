import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { RestaurantListPage } from './restaurant-list.page';
import { Camera } from '@ionic-native/camera/ngx';
import { WifiService } from 'src/app/services/wifi.service';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
const routes: Routes = [
  {
    path: '',
    component: RestaurantListPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  providers: [Camera,WifiService,LocationAccuracy, Geolocation],
  declarations: [RestaurantListPage]
})
export class RestaurantListPageModule {}
