import { Injectable } from '@angular/core';
import { Table } from 'src/app/models/table.model';

import { STORAGE_BONUSKARTEN, STORAGE_FAV_RESTAURANTS } from 'src/environments/constant';
import { DisplayService } from 'src/app/services/display.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public pushSubscription: { [restaurantKey: string]: boolean } = {};

  public pushAnalytics: boolean = false;

  public bonuscards: { [bonuscard: string]: number } = {};

  public currentTable: Table = null;

  public hasInitialized: boolean = false

  public favouriteRestaurants: string[] = []

  constructor(private displayService: DisplayService) {
    let key: any = localStorage.getItem(STORAGE_FAV_RESTAURANTS)
    if (key) key = JSON.parse(key)
    if (key && key.length > 0) {
      console.log("FAVORITE; Found in user.service lcoal storage")
      this.favouriteRestaurants = key
      this.displayService.favEnabled = true
      this.displayService.furtherLocalsEnabled = false
      console.log("LOOOOOADED from userervice", key)
    } else {
      this.favouriteRestaurants = []
    }
  }

  clickOnFavorite(restaurantKey: string) {
    console.log(restaurantKey)
    const index = this.favouriteRestaurants.indexOf(restaurantKey, 0);
    if (index > -1) {
      console.log("FAVORITE; Click on Favourite")
      this.favouriteRestaurants.splice(index, 1);
    } else {
      this.favouriteRestaurants.push(restaurantKey)
      this.displayService.setFavEnabled(true)
    }
    localStorage.setItem(STORAGE_FAV_RESTAURANTS, JSON.stringify(this.favouriteRestaurants))
  }

  getBonuscard(id: string): number {
    return this.bonuscards[id] ? this.bonuscards[id] : -1
  }

  get hasFavourites(): boolean {
    return this.favouriteRestaurants && this.favouriteRestaurants.length > 0
  }


  setBonuscard(id: string, value: number) {
    this.bonuscards[id] = value
    localStorage.setItem(STORAGE_BONUSKARTEN, JSON.stringify(this.bonuscards))
  }

  loadBonuscards() {
    let res = localStorage.getItem(STORAGE_BONUSKARTEN)
    this.bonuscards = res ? JSON.parse(res) : {}
  }
}
