export const APP_VERSION: string = "6.4.1"

export const SERVICE: string = "SERVICE"
export const CATEGORY: string = "CATEGORY"
export const SUBCATEGORY: string = "SUBCATEGORY"
export const ITEM: string = "ITEM"

export const STORAGE_REMIND_OPEN_RESTAURANT: string = "remindToOpenRestaurant"
export const STORAGE_INFORM_ABOUT_ORDER_VIA_PUSH: string = "informAboutOrdersViaPush"
export const STORAGE_BONUSKARTEN: string = "bonuskarten"
export const STORAGE_FAV_RESTAURANTS: string = "favRestaurants"
export const STORAGE_LAST_ROLE: string = "lastRole"
export const STORAGE_COLOR_THEME: string = "lastColorTheme"
export const STORAGE_MY_RESERVATIONS: string = "myReservations"
export const STORAGE_PHONE_CONFIRMATIONS:string = "confirmedPhonenumbers"
export const STORAGE_LAST_ORDER:string = "lastOrder"
export const STORAGE_USER_ID:string = "userId"
export const STORAGE_CORONA:string = "corona"

export const ANALYTICS_EV_START: string = "start"
export const ANALYTICS_EV_ORDER:string = "order"
//export const ANALYTICS_EV_CLICK_PUSH:string = "click-push"
export const ANALYTICS_EV_DIRECT_ENTRY: string = "direct-entry"
export const ANALYTICS_EV_SELECT_RESTAURANT: string = "select-restaurant"
export const ANALYTICS_EV_IMPRESSUM: string = "impressum"
//export const ANALYTICS_EV_OPEN_PAGE:string = "open-page"
export const ANALYTICS_EV_SPECIAL_CLICK: string = "click-special"

export const ANALYTICS_EV_OPEN_MENU: string = "open-menu"
export const ANALYTICS_EV_ADD_TO_BASKET: string = "add-to-basket"

export const ANALYTICS_EV_ORDER_START: string = "order-start"
export const ANALYTICS_EV_ORDER_NOT_MIN_AMOUNT: string = "not-min-amount"
export const ANALYTICS_EV_NO_ADDRESS_RECOGNIZED: string = "no-address-recognized"
export const ANALYTICS_EV_DATA_SUCCESSFULLY_ENTERED: string = "data-successfully-entered"
export const ANALYTICS_EV_ORDER_SEND_SMS: string = "order-send-sms"
export const ANALYTICS_EV_ORDER_NUMBER_ALREADY_CONFIRMED: string = "order-number-already-confirmed"
export const ANALYTICS_EV_ORDER_SEND_SMS_FAILED: string = "order-send-sms-failed"
export const ANALYTICS_EV_ORDER_CONTINUE_UNVERIFIED: string = "order-continue-unverified"
export const ANALYTICS_EV_ORDER_SENT_SMS: string = "order-sms-sent"
export const ANALYTICS_EV_ORDER_RESEND_SMS: string = "order-resend-sms"
export const ANALYTICS_EV_ORDER_SMS_CODE_CORRECT: string = "order-sms-code-correct"
export const ANALYTICS_EV_ORDER_SMS_CODE_INCORRECT: string = "order-sms-code-incorrect"
export const ANALYTICS_EV_ORDER_DIRECT_PAY: string = "order_direct_pay"
export const ANALYTICS_EV_ORDER_PAYPAL_PAY: string = "order_paypal_pay"
export const ANALYTICS_EV_ORDER_PAYPAL_CANCEL: string = "order_paypal_cancel"
export const ANALYTICS_EV_ORDER_PAYPAL_ERROR: string = "order_paypal_error"
export const ANALYTICS_EV_ORDER_ERROR: string = "order_error"
export const ANALYTICS_EV_CAPTCHA_ERROR: string = "captcha_error"
export const ANALYTICS_EV_GOOGLE_MAPS_SELECTED: string = "google_maps"
export const ANALYTICS_EV_SAVE_BASKET: string = "save-basket"
export const ANALYTICS_EV_LOAD_BASKET: string = "load-basket"
export const ANALYTICS_EV_CORONA_SMS_SENT: string = "corona-sms-sent"
export const ANALYTICS_EV_CORONA_SMS_VERIFIED: string = "corona-verified"
export const ANALYTICS_EV_CORONA_SMS_UNVERIFIED: string = "corona-unverified"
export const ANALYTICS_EV_CORONA_LOG_OUT: string = "corona-logout"
// export const ANALYTICS_EV_ADD_FAVOURITES: string = "add_fav"
// export const ANALYTICS_EV_REMOVE_FAVOURITES: string = "remove_fav"

export const STORAGE_PUSH_ANALYTICS:string = "shouldAnalyticsBePushed"

export const OWNER: string = "OWNER"
export const NOT_OWNER: string = "NOT_OWNER"
export const CUSTOMER: string = "CUSTOMER"
export const EMPLOYEE: string = "EMPLOYEE"
export const THREE_MINS: number = 210000
export const TWENTY_SEC: number = 20000
export const ADMIN_MAIL_ADRESSES: string[] = ['m.hassel@mamingo.de']

export const GMAPS_API_KEY: string = "AIzaSyCg8iYJMZOjIS9hbxO_GUI7W8LHiVRvG_0"

export const MICHAELS_GMX_FIREBASE_ID: string = "YUbXHLWX7ceiQQGaHrNeDAMu93i1"

//export const fcmUrl:string = "https://barela-ca314.appspot.com" 
export const fcmUrl: string = "https://barela-ca314.ew.r.appspot.com"
//export const fcmUrl:string = "http://localhost:8080"

export function createEvent(topic, data = {}) {
    return new CustomEvent(topic, {
        bubbles: true,
        detail: data
    });
}


export function getAllergene() {
    var allergene: { [id: string]: any } = {};
    allergene['a'] = { name: '(a) Glutenhaltiges Getreide', selected: false }
    allergene['b'] = { name: '(b) Krebstiere und daraus gewonnene Erzeugnisse', selected: false }
    allergene['c'] = { name: '(c) Eier und daraus gewonnene Erzeugnisse', selected: false }
    allergene['d'] = { name: '(d) Fische und daraus gewonnene Erzeugnisse', selected: false }
    allergene['e'] = { name: '(e) Erdnüsse und daraus gewonnene Erzeugnisse', selected: false }
    allergene['f'] = { name: '(f) Soja(bohnen) und daraus gewonnene Erzeugnisse', selected: false }
    allergene['g'] = { name: '(g) Milch und daraus gewonnene Erzeugnisse', selected: false }
    allergene['h'] = { name: '(h) Schalenfrüchte', selected: false }
    allergene['i'] = { name: '(i) Sellerie und daraus gewonnene Erzeugnisse', selected: false }
    allergene['j'] = { name: '(j) Senf und daraus gewonnene Erzeugnisse', selected: false }
    allergene['k'] = { name: '(k) Sesamsamen und daraus gewonnene Erzeugnisse', selected: false }
    allergene['l'] = { name: '(l) Schwefeldioxid und Sulphite', selected: false }
    allergene['m'] = { name: '(m) Lupinen und daraus gewonnene Erzeugnisse', selected: false }
    allergene['n'] = { name: '(n) Weichtiere und daraus gewonnene Erzeugnisse', selected: false }

    return allergene;
}

export function getZusatzstoffe() {
    var zusatzstoffe: { [id: string]: any } = {};
    zusatzstoffe['o'] = { name: '(1) mit Farbstoff', selected: false }
    zusatzstoffe['p'] = { name: '(2) mit Konservierungsstoff', selected: false }
    zusatzstoffe['q'] = { name: '(3) mit Antioxidationsmittel', selected: false }
    zusatzstoffe['r'] = { name: '(4) mit Geschmacksverstärker', selected: false }
    zusatzstoffe['s'] = { name: '(5) geschwefelt', selected: false }
    zusatzstoffe['t'] = { name: '(6) geschwärzt', selected: false }
    zusatzstoffe['u'] = { name: '(7) mit Phosphat', selected: false }
    zusatzstoffe['v'] = { name: '(8) mit Milcheiweiß (bei Fleischerzeugnissen)', selected: false }
    zusatzstoffe['w'] = { name: '(9) koffeinhaltig', selected: false }
    zusatzstoffe['x'] = { name: '(10) chininhaltig', selected: false }
    zusatzstoffe['y'] = { name: '(11) mit Süßungsmittel', selected: false }
    zusatzstoffe['z'] = { name: '(13) gewachst', selected: false }

    return zusatzstoffe;
}


/*

https://www.mps.mpg.de/kantine/zusatzstoffe
Deklarationspflichtige Zusatzstoffe
1 mit Farbstoff
2 mit Konservierungsstoff
3 mit Antioxidationsmittel
4 mit Geschmacksverstärker
5 geschwefelt
6 geschwärzt
7 mit Phosphat
8 mit Milcheiweiß (bei Fleischerzeugnissen)
9 koffeinhaltig
10 chininhaltig
11 mit Süßungsmittel
13 gewachst

Deklarationspflichtige Allergene
a Glutenhaltiges Getreide
b Krebstiere und daraus gewonnene Erzeugnisse
c Eier und daraus gewonnene Erzeugnisse
d Fische und daraus gewonnene Erzeugnisse
e Erdnüsse und daraus gewonnene Erzeugnisse
f Soja(bohnen) und daraus gewonnene Erzeugnisse
g Milch und daraus gewonnene Erzeugnisse
h Schalenfrüchte
i Sellerie und daraus gewonnene Erzeugnisse
j Senf und daraus gewonnene Erzeugnisse
k Sesamsamen und daraus gewonnene Erzeugnisse
l Schwefeldioxid und Sulphite
m Lupinen und daraus gewonnene Erzeugnisse
n Weichtiere und daraus gewonnene Erzeugnisse

Deklarationspflichtige Zusatzstoffe
o mit Farbstoff
p mit Konservierungsstoff
q mit Antioxidationsmittel
r mit Geschmacksverstärker
s geschwefelt
t geschwärzt
u mit Phosphat
v mit Milcheiweiß (bei Fleischerzeugnissen)
w koffeinhaltig
x chininhaltig
y mit Süßungsmittel
z gewachst

*/
