import { STORAGE_LAST_RESTAURANT } from '../../environments/environment.prod';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Injectable } from '@angular/core';
import { Restaurant } from '../models/restaurant.model';

import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { STORAGE_MY_ORDERS, STORAGE_ALL_MENUS, STORAGE_ALL_RESTAURANTS, STORAGE_ALL_RESERVATIONS, STORAGE_CACHE_BASKET } from '../../environments/environment';
import { Menu, Item, Category } from '../models/menu.model';
import { Order, OrderGroup } from '../models/order.model';

import { Events } from 'src/app/services/events.service';
import { UtilService } from '../services/util.service';
import { Table } from '../models/table.model';
import { UserService } from './user.service';
import { STORAGE_MY_RESERVATIONS, TWENTY_SEC, OWNER, CUSTOMER, NOT_OWNER, EMPLOYEE, ANALYTICS_EV_ORDER, ANALYTICS_EV_START, STORAGE_LAST_ORDER, APP_VERSION, fcmUrl, STORAGE_USER_ID } from '../../environments/constant';
import { PushService, TOPIC_NEW_ORDER, TOPIC_ORDER, TOPIC_ANALYTICS } from './push.service';
import { ReservationList, Reservation } from '../models/reservation.model';


//********************************** */
// REST API
//********************************** */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/internal/operators/first';
import { MailService } from 'src/app/services/mail.service';
import { CoronaGroup } from 'src/app/pages/shared/corona/corona.model';

@Injectable({
  providedIn: 'root'
})
export class DBService {

  public myPrivateOrders: OrderGroup[] = []
  public myPrivateReservations: Reservation[] = []

  public currentPage: string = 'home'
  private lastPageUpdate: number = -1
  public setPage(pageName: string) {
    this.util.log("1. Alte / Neue Seite: ", this.currentPage, pageName)
    let timeDiff: number = (new Date().getTime() - ((+this.lastPageUpdate) + 200))
    if (timeDiff < -900000) return//todo  org:0

    this.lastPageUpdate = +(new Date().getTime())
    this.util.log("2. Alte / Neue Seite: ", this.currentPage, pageName)
    this.currentPage = pageName;
  }

  public loggedUserUID: string;

  public contactMsgRef: any = this.firebase.list(this.util.dbversion + '/messages');
  public contactMsgRef2: any = this.firebase.list(this.util.dbversion + '/messages').valueChanges();
  public logsRef = this.firebase.list(this.util.dbversion + '/logs');
  public restaurantsRef = this.firebase.list<Restaurant>(this.util.dbversion + '/restaurants');
  public menuRef = this.firebase.list<Menu>(this.util.dbversion + '/menus');
  public reservationsRef = this.firebase.list<ReservationList>(this.util.dbversion + '/reservations');
  // public orderGroupsRef = this.firebase.list<OrderGroup>(this.util.dbversion + '/orders');

  public lastTableInRestaurant: { [restaurantKey: string]: Table } = {};

  public restaurants: { [id: string]: Restaurant } = {};
  public menus: { [id: string]: Menu } = {};
  public reservationList: { [id: string]: ReservationList } = {};
  public shadowCopyCategories: { [id: number]: Category } = {};
  public shadowCopyItems: { [id: number]: Item } = {};

  public currentRestaurantKey: string;
  public currentMenuKey: string;
  public currentReservationListKey: string;

  //Alle Bestellungen des Restaurants
  public currentOrderGroups: { [id: string]: OrderGroup } = {};
  public currentOrderGroupsAll: { [id: string]: OrderGroup } = {};
  public currentOrderGroupKeys: string[] = [];
  public orderChanged: boolean = false;

  public isOwner: boolean;
  public isEmployee: boolean;

  public basket: OrderGroup = new OrderGroup('');
  public placedOrderGroups: OrderGroup[] = [];

  constructor(private firebase: AngularFireDatabase, private vibration: Vibration, private events: Events,
    public util: UtilService, private userService: UserService, private pushService: PushService, private http: HttpClient, private mailService: MailService) {
    this.setMinVersion();
  }

  orderGroupRef(restaurantKey: string) {
    return this.firebase.list<OrderGroup>(this.util.dbversion + '/orders/' + restaurantKey);
  }

  public get currentRestaurant() {
    return this.restaurants[this.currentRestaurantKey]
  }

  public get currentMenu() {
    return this.menus[this.currentMenuKey]
  }


  //baseUrl = "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCg8iYJMZOjIS9hbxO_GUI7W8LHiVRvG_0&address=1600+Amphitheatre+Parkway,+Mountain+View,+CA"
  baseUrl = "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCg8iYJMZOjIS9hbxO_GUI7W8LHiVRvG_0&address="
  public getGeoLocationOfRestaurant(restaurant: Restaurant): any {
    if (!restaurant.address) restaurant.address = "unknown_address"
    let address: string = restaurant.address.replace(" ", "+")

    return this.http.get(this.baseUrl + address)
  }


  saveToStorage() {
    localStorage.setItem(STORAGE_ALL_RESERVATIONS, JSON.stringify(this.reservationList))
    localStorage.setItem(STORAGE_ALL_MENUS, JSON.stringify(this.menus))
    localStorage.setItem(STORAGE_ALL_RESTAURANTS, JSON.stringify(this.restaurants))
  }

  loadFromStorage() {
    let res = localStorage.getItem(STORAGE_ALL_MENUS)
    if (res) {
      this.menus = JSON.parse(res)
      // this.updateShadowCopyOfCategories();//eher nicht, sonst werden menus aus dem speichern von anderen restaurants ueberschrieben
    }

    res = localStorage.getItem(STORAGE_ALL_RESERVATIONS)
    if (res) {
      this.reservationList = JSON.parse(res)
    }

    res = localStorage.getItem(STORAGE_ALL_RESTAURANTS)
    if (res) {
      this.restaurants = JSON.parse(res)

      this.util.log('restaurantsLoadedFromStorage')

      this.events.publish('restaurantsLoadedFromStorage')
    }
  }

  sendMessage(msg: string) {
    this.contactMsgRef.push(msg)
  }

  sendLog(log: string) {
    if (this.util.logsPushEnabled)
      this.logsRef.push(new Date().toLocaleString() + ": " + log)
  }

  /*****************************************************/
  /************** R E S T A U R A N T ******************/
  /*****************************************************/
  /*
  exportSpeisekarte(): Subscription {
    return this.firebase.list('v1/menus/-LWG_bnnhjjI_I1_yelr').valueChanges().pipe(take(1)).subscribe((res: any) => {
      console.log("exportSpeisekarte;loading from database", res)
      for(let cat of res[0]){
        console.log(cat)
        for(let item of cat.items){
          console.log("A;16;"+item.name+";19;19;"+item.price+";0;x;;0")
        }
      }
    })
  }
  */

  loadRestaurants(): Subscription {
    let tmpRestaurants: { [id: string]: Restaurant } = {};
    return this.firebase.list(this.util.dbversion + '/restaurants').valueChanges().pipe(take(1)).subscribe((res: Restaurant[]) => {
      for (let restaurant of res) {
        tmpRestaurants[restaurant.id] = Object.assign(new Restaurant(restaurant.id), restaurant) //necessary to remove from storage, which are not existing anymore
        //this.subscribeRestaurant(restaurant.id)
      }
      this.restaurants = tmpRestaurants; //ueberschreibe werte von local storage
      for (let restaurant of res) {
        this.subscribeRestaurant(restaurant.id)
      }
      this.saveToStorage();

      this.events.publish('restaurantsLoaded')

      this.util.log("Restaurants wurden geladen", this.restaurants)
    })
  }

  get currentPreperationTime(): number{
    if(this.currentRestaurant.options && this.currentRestaurant.options.takeAwayTime && this.basket.mode == "take-away"){
      return +this.currentRestaurant.options.takeAwayTime
    }
    return this.currentRestaurant.defaultPreperationTime
  }

  tsSelectRestaurant: number = -1;
  /**
   * Wird aufgerufen, wenn ein Restaurant ausgewählt wird
   * @param key Restaurant Key von Firebase
   */
  selectRestaurant(key: string) {
    //*** Aktueller Tisch des letzten Restaurants zwischenspeichern ***/
    if (this.currentRestaurantKey && this.userService.currentTable) {
      this.lastTableInRestaurant[this.currentRestaurantKey] = this.userService.currentTable
    }

    localStorage.setItem(STORAGE_LAST_RESTAURANT, JSON.stringify(key))
    this.tsSelectRestaurant = new Date().getTime()
    this.isOwner = false;
    this.isEmployee = false
    this.currentRestaurantKey = key;
    if (this.lastTableInRestaurant[this.currentRestaurantKey]) {
      this.userService.currentTable = this.lastTableInRestaurant[this.currentRestaurantKey]
    } else {
      this.userService.currentTable = null
    }
    this.basket = new OrderGroup('');
    this.basket.requiredTimeInMinutes = this.currentPreperationTime
    this.refreshOwner()
    this.refreshEmployee()
    this.currentMenuKey = this.currentRestaurant.menuKey
    this.loadMenu(this.currentRestaurant.menuKey)
    if (this.isOwner || this.isEmployee) this.loadOrderGroups(this.currentRestaurantKey)
    this.currentOrders = this.firebase.list(this.util.dbversion + '/orders/' + this.currentRestaurantKey, ref => ref.orderByChild('restaurantKey').equalTo(this.currentRestaurantKey)).valueChanges();
    // this.currentMenu = this.firebase.object(this.util.dbversion + '/menus/' + this.currentRestaurant.menuKey).valueChanges();

    //Schauen, ob ReservationKey da ist, ansonsten erstellen
    if (this.currentRestaurant.reservationListKey) {
      this.currentReservationListKey = this.currentRestaurant.reservationListKey
      this.loadReservationList(this.currentRestaurant.reservationListKey)
    } else {
      let reservationList: ReservationList = new ReservationList('');
      this.reservationsRef.push(reservationList).then((val: any) => {
        reservationList.id = val.key
        this.reservationsRef.update(reservationList.id, reservationList)
        this.subscribeReservationList(reservationList.id)
        this.reservationList[reservationList.id] = reservationList
        this.restaurants[this.currentRestaurantKey].reservationListKey = reservationList.id
        this.updateRestaurant(this.currentRestaurantKey)
      })
    }

    //TODO unsubscribe bei anderen restaurants
    this.firebase.list(this.util.dbversion + '/orders/' + this.currentRestaurantKey, ref => ref.orderByChild('restaurantKey').equalTo(this.currentRestaurantKey)).snapshotChanges(['child_added'])
      .subscribe(actions => {
        actions.forEach(action => {
          if ((this.isOwner || this.isEmployee) && this.currentRestaurantKey === key && (new Date().getTime() - this.tsSelectRestaurant > 3000)) { //3s warten beim Restaurant wechsel, sonst vibrierts am Anfang
            this.vibration.vibrate([800, 1000, 2000, 1000, 1300]);
          }
        });
      });
  }

  addRestaurant(restaurant: Restaurant) {
    //var data = JSON.parse(JSON.stringify(restaurant));
    let promiseContainer: Promise<any>[] = []

    let menu: Menu = new Menu('');
    promiseContainer.push(this.menuRef.push(menu))
    let reservationList: ReservationList = new ReservationList('');
    promiseContainer.push(this.reservationsRef.push(reservationList))

    Promise.all(promiseContainer).then((res: any) => {
      menu.id = res[0].key;
      this.menuRef.update(menu.id, menu)
      this.subscribeMenu(menu.id)
      this.menus[menu.id] = menu
      restaurant.menuKey = menu.id

      reservationList.id = res[1].key;
      this.reservationsRef.update(reservationList.id, reservationList)
      this.subscribeReservationList(reservationList.id)
      this.reservationList[reservationList.id] = reservationList
      restaurant.reservationListKey = reservationList.id

      this.restaurantsRef.push(restaurant).then(res => {
        restaurant.id = res.key;
        this.restaurantsRef.update(restaurant.id, restaurant)
        this.subscribeRestaurant(restaurant.id)
        this.restaurants[restaurant.id] = restaurant
        this.events.publish('restaurant-created', restaurant.id)

        this.saveToStorage();
      })
    })

    // .catch(err => this.util.log(err, 'You dont have access!'));
    // itemRef.set({ restaurant});
  }

  updateRestaurant(key: string) {
    if (this.restaurants[key]) {
      this.firebase.object(this.util.dbversion + '/restaurants/' + key).update(this.restaurants[key])
      this.saveToStorage();
    }
  }

  removeRestaurant(key: string) {
    const promise = this.firebase.object(this.util.dbversion + '/restaurants/' + key).remove();
    promise
      .then(_ => {
        this.saveToStorage();
        delete this.restaurants[key]
        this.util.log('success')
      })
      .catch(err => this.util.log(err, 'You dont have access!'));
  }

  /**
   * @description Deletes restaurant and related menu and orders
   * @todo: Delete also reservations.
   * @param restaurantKey 
   */
  removeRestaurantsWithDependency(restaurantKey: string) {
    if (!restaurantKey) return
    let restaurant: Restaurant = this.restaurants[restaurantKey]
    if (!restaurant) return
    this.removeMenu(restaurant.menuKey)
    this.removeOrderGroupsByRestaurantKey(restaurantKey)
    //TODO: Reservations
    this.removeRestaurant(restaurantKey)
  }

  /**
 * Deletes all restaurant and related menu and orders from one user, which is owner is all those restaurants.
 * @param restaurantKey 
 */
  removeRestaurantsByOwner(userID: string) {
    let restaurants2Delete: string[] = this.getRestaurantKeys(OWNER, userID)
    for (let restaurantKey of restaurants2Delete) {
      this.removeRestaurantsWithDependency(restaurantKey)
    }

  }



  subscribeRestaurant(key: string) {
    this.firebase.object(this.util.dbversion + '/restaurants/' + key).valueChanges().subscribe((changedRestaurant: Restaurant) => {
      if (!changedRestaurant) return
      this.restaurants[key] = changedRestaurant
      this.saveToStorage();
      if (key == changedRestaurant.id) {
        this.restaurants[key] = Object.assign(new Restaurant(changedRestaurant.id), changedRestaurant)
        this.util.log("Restaurant wurde aktualisiert", this.restaurants[key])
        this.events.publish('restaurantUpdated')
      }
      // this.loggedTodos = changedTodos
    });
  }

  /**
   * Returns restaurants dependent on parameter:
   *  CUSTOMER: All restaurants
   *  OWNER: Only restaurants, which belongs to owner
   *  NOT_OWNER: Only restaurants, which do not belong to owner
   */
  getRestaurantKeys(filterOption: string, userID: string = this.loggedUserUID): string[] {
    if (filterOption === OWNER) {
      return Object.keys(this.restaurants).filter(
        key => this.isOwnerFunc(key, userID));
    } else if (filterOption === EMPLOYEE) {
      return Object.keys(this.restaurants).filter(
        key => this.isEmployeeFunc(key, userID));
    } else if (filterOption === NOT_OWNER) {
      return Object.keys(this.restaurants).filter(
        key =>
          !(this.isOwnerFunc(key, userID) || this.isEmployeeFunc(key, userID))
      );
    }
    return Object.keys(this.restaurants)
  }

  refreshOwner() {
    this.isOwner = this.isOwnerFunc(this.currentRestaurantKey, this.loggedUserUID)
  }

  refreshEmployee() {
    this.isEmployee = this.isEmployeeFunc(this.currentRestaurantKey, this.loggedUserUID)
  }

  isOwnerFunc(key: string, userID: string, ignoreEmployeeMode: boolean = false): boolean {
    if (!this.restaurants[key] || (ignoreEmployeeMode === false && this.util.employeeMode === true)) return false  //Falls Mitarbeiter-Modus drin ist...
    if (this.util.isAdmin && userID === this.loggedUserUID) return true
    else return (this.restaurants[key].owners.find(x => x === userID) !== undefined);
  }

  isEmployeeFunc(key: string, userID: string): boolean {
    if (!this.restaurants[key]) return false
    if (this.isOwnerFunc(key, userID, true)) return true
    else return (this.restaurants[key].employees.find(x => x === userID) !== undefined);
  }

  /******************************************************/
  /********* R E S E R V A T I O N **********************/
  /******************************************************/
  loadReservationList(reservationListKey: string) {
    //Muss nicht abgefragt werden, ob er im Restaurant gerade ist, weil man es nur einmal holt!
    this.firebase.object(this.util.dbversion + '/reservations/' + reservationListKey).valueChanges().pipe(take(1)).subscribe((res: ReservationList) => {
      if (res && res.id) {
        this.reservationList[res.id] = Object.assign(new ReservationList(res.id), res)
        this.util.log("ReservationList wurden geladen", this.reservationList[res.id])
        this.currentReservationListKey = res.id
        this.subscribeReservationList(res.id)
        this.saveToStorage();
      } else {
        //TODO: es gibt keine reservation list
      }
    })
  }

  selectReservationList(key: string) {
    if (this.reservationList[key]) {
      this.currentReservationListKey = key;
    } else {
      this.loadReservationList(key);
    }
  }

  updateReservationList(key: string) {
    if (this.reservationList[key]) {
      this.util.log("ReservationList wird aktualisiert: ", this.reservationList[key])
      this.firebase.object(this.util.dbversion + '/reservations/' + key).update(this.reservationList[key])
      this.saveToStorage();
    }
  }

  deleteReservation(reservation: Reservation) {
    this.firebase.list(this.util.dbversion + '/reservations/' + reservation.parentID + '/reservations/', ref => ref.orderByChild('id').equalTo(reservation.id)).snapshotChanges().pipe(take(1)).subscribe((res: any) => {
      if (res && res.length > 0 && res[0].payload) {
        res[0].payload.ref.remove()
      }
    })
  }

  removeReservationList(key: string) {
    const promise = this.firebase.object(this.util.dbversion + '/reservations/' + key).remove();
    promise
      .then(_ => {
        this.util.log('success')
        this.saveToStorage();
      })
      .catch(err => this.util.log(err, 'You dont have access!'));
  }

  subscribeReservationList(key: string) {
    this.firebase.object(this.util.dbversion + '/reservations/' + key).valueChanges().subscribe((changedReservationList: ReservationList) => {
      if (!changedReservationList) return;
      if (key == changedReservationList.id) {
        this.reservationList[key] = Object.assign(new ReservationList(changedReservationList.id), changedReservationList)
        this.saveToStorage();
        // this.updateShadowCopyOfCategories(this.reservations[key]);  //brauch ich glaub ich hier nicht
        this.util.log("ReservationList wurde aktualisiert", this.reservationList[key], this.currentPage)
      }
    });
  }

  //TODO RESERVATIONLIST KOMPLETT SO NUR BEI OWNER AKTUALISIERT WERDEN!
  subscribeForLocalReservations(reservation: Reservation) {
    this.firebase.list(this.util.dbversion + '/reservations/' + reservation.parentID + '/reservations/', ref => ref.orderByChild('id').equalTo(reservation.id)).valueChanges().subscribe((res: Reservation[]) => {
      this.util.log("Local Reservations aktualisiert", res)
      if (!res || res.length == 0) {
        //this.myPrivateReservations = this.util.deleteFromArray(this.myPrivateReservations, reservation)  //Warum auch immer, geht net
        let index = -1;
        let found = false
        for (let _res of this.myPrivateReservations) {
          index++
          if (_res.id == reservation.id) {
            found = true
            break;
          }
        }
        if (found && index > -1) {
          this.myPrivateReservations.splice(index, 1);
        }

        localStorage.setItem(STORAGE_MY_RESERVATIONS, JSON.stringify(this.myPrivateReservations));
        return
      }

      //Todo: Workaround, da folgendes nicht geht: Updaten in array, Speichern in storage und am anfang beim laden alle holen
      //1. alle reservation im store durchgehen
      //2. fuer alle ein subscribe anlegen
      let tmpReservation = this.myPrivateReservations.find((reserv: Reservation) => reserv.id === res[0].id && reserv.parentID === res[0].parentID);

      if (tmpReservation) {
        let index = this.myPrivateReservations.indexOf(tmpReservation);
        if (index > -1) {
          this.myPrivateReservations[index] = (Object.assign(new Reservation(), res[0]));
          localStorage.setItem(STORAGE_MY_RESERVATIONS, JSON.stringify(this.myPrivateReservations));
        }
      }
    })
  }

  /*****************************************************/
  /******************* M E N U *************************/
  /*****************************************************/
  //public currentMenu: Observable<any>;

  loadMenu(menuKey: string) {
    //Muss nicht abgefragt werden, ob er im Restaurant gerade ist, weil man es nur einmal holt!
    this.firebase.object(this.util.dbversion + '/menus/' + menuKey).valueChanges().pipe(take(1)).subscribe((res: Menu) => {
      this.menus[res.id] = Object.assign(new Menu(res.id), res)
      this.util.log("Menu wurden geladen", this.menus[res.id])
      this.currentMenuKey = res.id
      this.updateShadowCopyOfCategories(this.menus[res.id]);
      this.subscribeMenu(res.id)
      this.saveToStorage();
    })
  }

  selectMenu(key: string) {
    if (this.menus[key]) {
      this.currentMenuKey = key;
      this.updateShadowCopyOfCategories(this.menus[key]);
    } else {
      this.loadMenu(key);
    }
  }

  updateMenu(key: string) {
    if (this.menus[key]) {
      this.updateShadowCopyOfCategories(this.menus[key]);
      this.util.log("Menü wird aktualisiert: ", this.menus[key])
      this.firebase.object(this.util.dbversion + '/menus/' + key).update(this.menus[key])
      this.saveToStorage();
    }
  }

  removeMenu(key: string) {
    const promise = this.firebase.object(this.util.dbversion + '/menus/' + key).remove();
    promise
      .then(_ => {
        delete this.menus[key]
        this.util.log('success')
        this.saveToStorage();
      })
      .catch(err => this.util.log(err, 'You dont have access!'));
  }

  subscribeMenu(key: string) {
    this.firebase.object(this.util.dbversion + '/menus/' + key).valueChanges().subscribe((changedMenu: Menu) => {
      if (!changedMenu) return;
      // this.menus[key] = changedMenu
      if (key == changedMenu.id) {
        this.menus[key] = Object.assign(new Menu(changedMenu.id), changedMenu)
        this.saveToStorage();
        this.updateShadowCopyOfCategories(this.menus[key]);
        this.util.log("Menu wurde aktualisiert", this.menus[key], this.currentPage)
      }
    });
  }

  //TODO Perf Problem
  updateItem(newItem: Item) {
    if (!this.menus[this.currentMenuKey]) return

    this.menus[this.currentMenuKey].category.forEach((cat, index1) => {
      if (cat.items) {
        cat.items.forEach((item, index2) => {
          if (item.id === newItem.id) {
            this.menus[this.currentMenuKey].category[index1].items[index2] = newItem
            return
          }
        })
      }
    });

    this.menus[this.currentMenuKey].category.forEach((cat, index1) => {
      if (cat.categories) {
        cat.categories.forEach((subCat, index3) => {
          if (subCat.items) {
            subCat.items.forEach((item, index2) => {
              if (item.id === newItem.id) {
                this.menus[this.currentMenuKey].category[index1].categories[index3].items[index2] = newItem
                return
              }
            })
          }
        })
      }
    })
  }

  findArticleByID(id: number) {
    // if(orByItem) itemKey = orByItem.id;
    if (!this.menus[this.currentMenuKey]) return
    if (this.shadowCopyItems[id]) return this.shadowCopyItems[id] //erstmal bei den Shadows schauen
    if (this.shadowCopyCategories[id]) return this.shadowCopyCategories[id]

    for (let cat of this.menus[this.currentMenuKey].category) {
      if (!cat) continue;
      if (cat.id === id) return cat
      if (cat.categories)
        for (let subCat of cat.categories) {
          if (!subCat || !subCat.items) continue;
          if (subCat.id === id) return subCat
          for (let item of subCat.items) {
            if (item && item.id === id) return item
          }
        }
      if (cat.items) {
        for (let item of cat.items) {
          if (item.id === id) return item
        }
      }
    }

    return null;
  }

  //TODO Perf Problem
  findItem(itemKey: number, orByItem?: Item): Item {
    // if(orByItem) itemKey = orByItem.id;
    if (!this.menus[this.currentMenuKey]) return
    if (this.shadowCopyItems[itemKey]) return this.shadowCopyItems[itemKey] //erstmal bei den Shadows schauen
    console.error("Achtung: Item nicht in Shadow Copy gefunden", itemKey, this.shadowCopyItems)

    for (let cat of this.menus[this.currentMenuKey].category) {
      if (!cat || !cat.items) continue;
      for (let item of cat.items) {
        if (item.id === itemKey) {
          return item
        }
      }
    }

    //Sub-Categories
    for (let cat of this.menus[this.currentMenuKey].category) {
      if (!cat || !cat.categories) continue;
      for (let subCat of cat.categories) {
        if (!subCat || !subCat.items) continue;
        for (let item of subCat.items) {
          if (item && item.id === itemKey) {
            return item
          }
        }
      }
    }

    return null;
  }

  findParentCategory(childCat: Category): Category {
    if (!this.menus[this.currentMenuKey] || !childCat) return
    if (childCat.id !== childCat.parentCatID && this.shadowCopyCategories[childCat.parentCatID]) return this.shadowCopyCategories[childCat.parentCatID] //erstmal bei den Shadows schauen
    console.error("Achtung: Parent Kateogry nicht in Shadow Copy anhand von Kategory gefunden", childCat, this.shadowCopyCategories)

    for (let cat of this.menus[this.currentMenuKey].category) {
      if (!cat || !cat.categories) continue;
      for (let subCat of cat.categories) {
        if (childCat.id === subCat.id) {
          return cat
        }
      }
    }

    return null
  }

  findCategory(itemKey: number): Category {
    if (!this.menus[this.currentMenuKey]) return
    if (this.shadowCopyItems[itemKey] && this.shadowCopyCategories[this.shadowCopyItems[itemKey].parentCatID]) return this.shadowCopyCategories[this.shadowCopyItems[itemKey].parentCatID] //erstmal bei den Shadows schauen

    console.error("Achtung: Kateogry nicht in Shadow Copy anhand von Item gefunden", itemKey, this.shadowCopyCategories)
    for (let cat of this.menus[this.currentMenuKey].category) {
      if (!cat || !cat.items) continue;
      for (let item of cat.items) {
        if (item.id === itemKey) {
          return cat
        }
      }
    }

    //Sub-Categories
    for (let cat of this.menus[this.currentMenuKey].category) {
      if (!cat || !cat.categories) continue;
      for (let subCat of cat.categories) {
        if (!subCat || !subCat.items) continue;
        for (let item of subCat.items) {
          if (item.id === itemKey) {
            return subCat
          }
        }
      }
    }

    return null;
  }


  findCategoryByCat(cat2find: Category): Category {
    if (!cat2find || !this.menus[this.currentMenuKey]) return
    if (this.shadowCopyCategories[cat2find.id]) return this.shadowCopyCategories[cat2find.id] //erstmal bei den Shadows schauen
    console.error("Achtung: Kateogry nicht in Shadow Copy anhand von Kategory gefunden", cat2find, this.shadowCopyCategories)

    for (let cat of this.menus[this.currentMenuKey].category) {
      if (!cat) continue;
      if (cat.name === cat2find.name) {
        if (!cat.items) cat.items = []
        return cat
      }
    }

    //Sub-Categories
    for (let cat of this.menus[this.currentMenuKey].category) {
      if (!cat || !cat.categories) continue;
      for (let subCat of cat.categories) {
        if (!subCat) continue;
        if (subCat.name === cat2find.name) {
          if (!subCat.items) subCat.items = []
          return subCat
        }
      }
    }

    return undefined;
  }


  updateShadowCopyOfCategories(menu: Menu) {
    //TODO nicht mehr gebrauchte loeschen
    if (menu.category) {
      for (let cat of menu.category) {
        this.shadowCopyCategories[cat.id] = cat
        if (cat.categories) {
          for (let subCat of cat.categories) {
            this.shadowCopyCategories[cat.id] = cat

            if (subCat.items) {
              for (let item of subCat.items) {
                this.shadowCopyItems[item.id] = item
              }
            }
          }
        }

        if (cat.items) {
          for (let item of cat.items) {
            this.shadowCopyItems[item.id] = item
          }
        }
      }
    }
  }

  /*****************************************************/
  /******************* O R D E R ***********************/
  /*****************************************************/
  public currentOrders: Observable<any>;

  getAllOrdersByTableName(tableName: string) {
    return this.firebase.list(this.util.dbversion + '/orders/' + this.currentRestaurantKey, ref => ref.orderByChild('source').equalTo(tableName)).valueChanges().pipe(take(1))
  }

  //TODO Beschränken auf die letzten 1000 Orders?
  loadOrderGroups(restaurantKey: string) {
    this.util.log("ENTER_METHOD: loadOrderGroups mit RestaurantKey: ", restaurantKey)
    this.currentOrderGroups = {}
    this.currentOrderGroupKeys = []
    this.currentOrderGroupsAll = {}
    //TODO: Limit to last 200
    //this.firebase.list(this.util.dbversion + '/orders', ref => ref.orderByChild('restaurantKey').equalTo(restaurantKey).limitToLast(2000)).valueChanges().subscribe((res: Order[]) => {
    this.firebase.list(this.util.dbversion + '/orders/' + restaurantKey, ref => ref.orderByChild('timestamp').limitToLast(100)).valueChanges().subscribe((res: Order[]) => {
      this.util.log("METHOD: loadOrderGroups => ValueChangesSubscribe: ", res)

      let orders: OrderGroup[] = []
      for (let order of res) {
        orders.push((Object.assign(new OrderGroup(order.id), order)))
        if (order.id)
          this.currentOrderGroupsAll[order.id] = Object.assign(new OrderGroup(order.id), order)
      }

      let newCurrentOrders: { [id: string]: OrderGroup } = {}
      let activeServices: string[] = []

      //Service nur einmal anzeigen pro Tisch, der nicht abgearbeitet ist
      for (let i = orders.length - 1; i >= 0; i--) {
        if (orders[i].isService && orders[i].order.length === 1 && orders[i].order[0].state != 2) {
          if (activeServices.indexOf(orders[i].order[0].fixedItemName) === -1) {
            activeServices.push(orders[i].order[0].fixedItemName)
            newCurrentOrders[orders[i].id] = Object.assign(new OrderGroup(orders[i].id), orders[i])
          }
        } else {
          newCurrentOrders[orders[i].id] = Object.assign(new OrderGroup(orders[i].id), orders[i])
        }
      }


      if (restaurantKey === this.currentRestaurantKey) {
        this.currentOrderGroups = newCurrentOrders
        this.currentOrderGroupKeys = Object.keys(this.currentOrderGroups).reverse()
        //*************************************************************** */
        this.newLengthCurrentOrderGroupKeys = this.currentOrderGroupKeys.length;
        if (this.newLengthCurrentOrderGroupKeys > this.oldLengthCurrentOrderGroupKeys && this.oldLengthCurrentOrderGroupKeys > -1) {
          this.newOrder = true;

          //play sound
          this.events.publish('newOrder')

          setTimeout(() => {
            this.newOrder = false
          }, 7000);
        }
        this.oldLengthCurrentOrderGroupKeys = this.newLengthCurrentOrderGroupKeys
        //*************************************************************** */

        this.orderChanged = true
      }

      this.util.log('METHOD: loadOrderGroups => ValueChangesSubscribe Orders loaded:', this.currentOrderGroups)
    })
  }

  //************ Fuer schreiben, dass was neues da ist... ***/
  oldLengthCurrentOrderGroupKeys: number = -1;
  newLengthCurrentOrderGroupKeys: number = 0;
  newOrder: boolean = false;
  //******************************************************** */

  getText(textId: number, orderGroup: OrderGroup) {
    if (textId == 0) {
      if (orderGroup.mode == "eat-here") return 'Eine neue Bestellung ist eingegangen (' + orderGroup.source + ')';
      if (orderGroup.mode == "delivery") return 'Eine neue Bestellung ist eingegangen (zum Liefern: ' + orderGroup.address + ')';
      if (orderGroup.mode == "take-away") return 'Eine neue Bestellung ist eingegangen (zur Abholung von: ' + orderGroup.name + ')';
    }

    return '';
  }

  notSentOrderGroups: { [orderGroupTS: number]: OrderGroup } = {};
  addOrderGroup(orderGroup: OrderGroup, paypal: boolean = false) {
    if (this.currentRestaurant.confirmOrderAutomatically) orderGroup.confirmed = true
    this.notSentOrderGroups[orderGroup.timestamp] = orderGroup

    this.orderGroupRef(orderGroup.restaurantKey).push(orderGroup).then(resOrder => {
      //Pruefen ob die Bestellung im zeitl. Rahmen liegt
      if (this.notSentOrderGroups[orderGroup.timestamp] && ((new Date().getTime() - orderGroup.timestamp) < TWENTY_SEC)) {
        //Send Push Message to Owner
        this.pushService.sendPush('Neue Bestellung (' + this.currentRestaurant.name + ')', this.getText(0, orderGroup), TOPIC_NEW_ORDER, TOPIC_NEW_ORDER, this.currentRestaurantKey).subscribe((res: any) => {
          console.log(res)
        })

        //Bonuskarten wieder disabled
        this.util.enableBonuskarten = false

        if (!orderGroup.isService) {
          this.basket = new OrderGroup('') //Einkaufskorb zuruecksetzen
          localStorage.setItem(STORAGE_CACHE_BASKET + "/" + this.currentRestaurantKey, JSON.stringify(this.basket))
        }
        this.basket.requiredTimeInMinutes = this.currentPreperationTime
        orderGroup.id = resOrder.key;
        this.orderGroupRef(orderGroup.restaurantKey).update(orderGroup.id, orderGroup)
        this.events.publish('orderSent', { orderGroup: orderGroup })

        //wenn per Paypal bezahlt, bekommt er an anderer Stelle eine Mail
        if (!paypal) {
          this.mailService.sendMail(orderGroup, this.currentRestaurant.mail).subscribe(val => { console.log(val) })
          this.mailService.sendOrderConfirmation(orderGroup, orderGroup.mail, this.currentRestaurant.name).subscribe(val => { console.log(val) })
        }

        //Subscribe for this order to receive updates (e.g. changed delivery Time)
        this.pushService.subscribeToTopic(TOPIC_ORDER + "-" + orderGroup.id)

        this.addAnalytics(ANALYTICS_EV_ORDER, { "id": orderGroup.id, "mode": orderGroup.mode })

        //*** Und im privaten nach vergabe des Keys speichern */
        this.myPrivateOrders.push(orderGroup); //Store meine privaten Orders lokal; unshift= an 1. Stelle
        localStorage.setItem(STORAGE_MY_ORDERS, JSON.stringify(this.myPrivateOrders))
        if (orderGroup.mode == 'eat-here') localStorage.setItem(STORAGE_LAST_ORDER, JSON.stringify(new Date().getTime()))  //only safe when eat-here, otherwise user must confirm his data
        this.subscribeForLocalOrders(orderGroup)
      } else {
        //Sonst wird die bestellung gelich wieder geloescht
        this.removeOrderGroup(resOrder.key)
      }
      this.notSentOrderGroups[orderGroup.timestamp] = undefined
    })
  }

  subscribeForLocalOrders(orderGroup: OrderGroup) {
    this.firebase.object(this.util.dbversion + '/orders/' + orderGroup.restaurantKey + '/' + orderGroup.id).valueChanges().subscribe((res: OrderGroup) => {
      this.util.log("Update fuer local Order 1: (Update, myPrivateOrders)", res, this.myPrivateOrders)
      let tmpOrder;
      for (let privateOrders of this.myPrivateOrders) {
        if (res && res.id === privateOrders.id) {
          this.util.log("Update fuer local Order 2: ", res)
          tmpOrder = privateOrders
          //Todo: Workaround, da folgendes nicht geht: Updaten in array, Speichern in storage und am anfang beim laden alle holen
          //1. alle order im store durchgehen
          //2. fuer alle ein subscribe anlegen
          break;
        }
      }
      if (tmpOrder) {
        let index = this.myPrivateOrders.indexOf(tmpOrder);
        this.util.log("Update fuer local Order 1, Tmp Order gefunden", index)
        if (index > -1) {
          this.myPrivateOrders[index] = (Object.assign(new OrderGroup(res.id), res));
          localStorage.setItem(STORAGE_MY_ORDERS, JSON.stringify(this.myPrivateOrders))
        }
      }
    })
  }

  updateOrderGroup(orderGroup: OrderGroup) {
    this.firebase.object(this.util.dbversion + '/orders/' + orderGroup.restaurantKey + '/' + orderGroup.id).update(orderGroup)
  }

  removeOrderGroup(key: string) {
    const promise = this.firebase.object(this.util.dbversion + '/orders/' + this.currentRestaurantKey + '/' + key).remove();
    promise
      .then(_ => {
        this.util.log('success')
      })
      .catch(err => this.util.log(err, 'You dont have access!'));
  }

  removeOrderGroupsByRestaurantKey(restaurantKey: string) {
    return this.firebase.list(this.util.dbversion + '/orders/' + restaurantKey, ref => ref.orderByChild('restaurantKey').equalTo(restaurantKey)).remove()
  }

  addToBasket(newOrderItem: Order) {
    if (!this.basket || !this.basket.restaurantKey) {
      this.basket = new OrderGroup('');
      this.basket.requiredTimeInMinutes = this.currentPreperationTime
      this.basket.restaurantKey = this.currentRestaurantKey
    }

    //Falls bereits in Korb gibt, amount erhoehen
    /*
    //Funktion geloescht, da ansonsten die Notizen der einzelnen Sachen verloren gehen...
    for (let orderItem of this.basket.order) {
      if (orderItem.itemKey === newOrderItem.itemKey) {
        orderItem.amount += newOrderItem.amount
        return //Falls gefunden, Amount hinzufügen und raus
      }
    }*/

    this.basket.order.push(newOrderItem)
    localStorage.setItem(STORAGE_CACHE_BASKET + "/" + this.currentRestaurantKey, JSON.stringify(this.basket))
  }

  removeFromBasket(orderItem: Order) {
    const index = this.basket.order.indexOf(orderItem, 0);
    if (index > -1) {
      this.basket.order.splice(index, 1);
    }
    localStorage.setItem(STORAGE_CACHE_BASKET + "/" + this.currentRestaurantKey, JSON.stringify(this.basket))
  }

  isBasketFilled(): boolean {
    for (let orderItem of this.basket.order) {
      let amount = orderItem.amount
      let item = this.findItem(orderItem.itemKey)
      if ((amount && item) || orderItem.bonuscardPrice > -1) {
        return true;
      }
    }

    return false;
  }

  getBasketPrice(bonuskarteEnabled: boolean = false, orderGroup?: OrderGroup): number {
    let price: number = 0;

    if (!orderGroup) orderGroup = this.basket
    for (let orderItem of orderGroup.order) {
      let amount = orderItem.amount
      let item = this.findItem(orderItem.itemKey)
      let itemPrice;
      if (amount && item) {
        if (orderItem.bonuscardPrice !== -1 && bonuskarteEnabled) {
          itemPrice = orderItem.bonuscardPrice
        } else if (item.takeAwayPrice > -1 && orderGroup.mode == "take-away") {
          itemPrice = item.takeAwayPrice
        } else if (item.isSpecialPriceActive) {
          itemPrice = item.specialPrice
        } else {
          itemPrice = item.price
        }
      } else if (amount && orderItem.fixedPrice) {
        itemPrice = orderItem.fixedPrice
      }

      if (amount && (item || orderItem.fixedPrice)) {
        price += (itemPrice * amount)

        if (orderItem.extraPrice) {
          price += (amount * orderItem.extraPrice)
        }

        if (orderItem.optionPrice > 0) {
          price += (amount * orderItem.optionPrice)
        }
      }
    }

    return price;
  }
  
  supportsOrders(): boolean{
    try{
      return this.currentRestaurant.orderMode['eat-here'] == true || this.currentRestaurant.orderMode['delivery'] == true || this.currentRestaurant.orderMode['take-away'] == true
    }catch(e){
      return true;
    }
  }

  orderBasket(orderGroup: OrderGroup, paypal: boolean = false) {
    let tmpItem: Item;

    if (orderGroup && orderGroup.order.length > 0) {
      orderGroup.restaurantKey = this.currentRestaurantKey
      orderGroup.timestamp = new Date().getTime()
      if (!orderGroup.source) {
        orderGroup.source = ' ? '
      }

      for (let order of orderGroup.order) {
        if (!orderGroup.isService) {
          tmpItem = this.findItem(order.itemKey)
          order.fixedCatName = this.findCategory(order.itemKey).name
          order.fixedItemName = tmpItem.name
          order.fixedPrice = tmpItem.takeAwayPrice > -1 && orderGroup.mode == "take-away" ? tmpItem.takeAwayPrice : (tmpItem.isSpecialPriceActive ? tmpItem.specialPrice : tmpItem.price)
          order.state = 0;
          order.amount = Math.round(order.amount)

          if (!this.util.enableBonuskarten) {
            //  if(order.bonuscardPrice !== -1) order.amountPartInBonus = order.amountPartInBonus - order.amount  //TODO
            order.bonuscardPrice = -1
          }
        }
        if (!order.extraString) order.extraString = ''
        if (!order.optionString) order.optionString = ''
      }

      this.addOrderGroup(orderGroup, paypal)
      //this.placedOrderGroups.unshift(this.basket)
    }
  }

  get amountBasketItems(): number {
    let no = 0;

    if (!this.basket.order) return 0;
    for (let orderItem of this.basket.order) {
      no += (orderItem.amount)
    }

    return no;
  }







  /*****************************************************/
  /******************* C O R O N A *********************/
  /*****************************************************/
  addCoronaGroup(coronaGroup: CoronaGroup) {
    let result = this.firebase.list('/corona/' + this.util.dbversion + '/' + coronaGroup.restaurantId).push(coronaGroup)
    return result.key
  }

  updateCoronaGroup(retaurantId, id, until) {
    this.firebase.list('/corona/' + this.util.dbversion + '/' + retaurantId).update(id, { until: until })
  }

  updateCoronaGroupWithId(retaurantId, coronaGroup: CoronaGroup, id) {
    this.firebase.list('/corona/' + this.util.dbversion + '/' + retaurantId).update(id, coronaGroup)
  }

  coronaList(retaurantId: string, timestamp = 0) {
    return this.firebase.list('/corona/' + this.util.dbversion + '/' + retaurantId, ref => ref.orderByChild('timestamp').startAt(timestamp)).valueChanges()
  }





  private sessionId: string = ""
  private userId: string = ""
  startAnalytics() {
    this.sessionId = this.util.uuidv4()

    let res = localStorage.getItem(STORAGE_USER_ID)
    if (res) this.userId = res
    else {
      this.userId = this.util.uuidv4()
      localStorage.setItem(STORAGE_USER_ID, this.userId)
    }

    this.addAnalytics(ANALYTICS_EV_START, { "user_id": this.userId, "session_id": this.sessionId, "device": this.util.p_platform, "version": APP_VERSION })
  }

  addAnalytics(eventName: string, data: {} = {}) {
    data["eventName"] = eventName
    data["customer"] = !this.loggedUserUID
    if (this.currentRestaurantKey){
      data["restaurant"] = this.currentRestaurantKey
      data["restaurantName"] = this.currentRestaurant.name
    } 
    data["timestamp"] = new Date().getTime()

    this.pushService.sendPush('Analytics: ' + data["eventName"] + '(' + this.sessionId + ')', JSON.stringify(data), TOPIC_ANALYTICS, this.sessionId).subscribe((res: any) => { })
    this.firebase.list('/analytics/' + this.util.dbversion + '/' + this.sessionId).push(data)
  }

  setMinVersion() {
    let device: string = "android"
    if (this.util.isIOS()) device = "ios"
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    let _this = this
    this.http.get<any>(`${fcmUrl}/min-version/${device}`, { observe: 'response', headers: headers }).subscribe(val => {
      if (val && val.body)
        _this.util.setIsVersionTooOld(val.body.version)
    })
  }

}

