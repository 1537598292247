export class Bonuskarte {

    public id:string
    public isActive:boolean = true
    public title: string
    public desc: string
    public color: string = "servicegreen"
    public collectCategroies:boolean
    public bonusAsCategroy:boolean
    public id2Collect:number //kann Kategorie oder Item sein
    public requiredAmount:number = 10

    public idAsBonus:number //kann Kategorie oder Item sein
    public bonusAmount:number = 1
    public startDate:number = new Date().getTime()
    public endDate:number = 2240521200000

    //Item, ob im Warenkorb die Karte bereits verwendet wurde
    //public virtualUsed:boolean = false
    
    public constructor(restaurantKey:string) {
        this.id = ('')+new Date().getTime()+restaurantKey
    }

}
