


/**
 * Order in a restaurant
 */
export class Order {

    public done: boolean //deprecated
    public state: number = 0 //0=neu; 1=ready;2=done
    public canceled: boolean
    public itemKey: number;
    public amount: number;
    public note: string;
    public extraString: string  = '';
    public extraPrice: number;
    public optionString: string  = '';
    public optionPrice: number  = 0;

    public fixedCatName:string = ''
    public fixedItemName:string = ''
    public fixedPrice:number = 0
    public bonuscardPrice:number = -1
    
    //only required for local Orders
    public amountPartInBonus:number = 0;


    //id wird aktuell nicht mehr gebraucht
    constructor(public id: string) {

    }

}


export class OrderGroup {
    public order: Order[] = []
    public source: string; //Table, ...
    public mode: string = "eat-here"
    public timestamp: number;
    public requiredTimeInMinutes: number = 30;
    public note: string = "";
    public noteFromRestaurant: string = "";
    public confirmed:boolean = false;
    public getippt:boolean = false;
    public paid:boolean = false;
    public isService:boolean = false;
    public tip: number = 0
    public ecCard: boolean = false
    public bringBox: boolean = false

    public restaurantKey: string;

    public isNativeApp: boolean = false
    public address: string = ""
    public name: string = ""
    public phone: string = ""
    public mail: string = ""

    constructor(public id: string, mode = "eat-here") {

    }
}

