export class ReservationList {
    public reservations: Reservation[] = []

    constructor(public id: string) {

    }
}

export class Reservation {

    public name:string

    public date:number = new Date().getTime()

    public confirmed:boolean = false

    public canceled:boolean = false

    public denied:boolean = false

    public accepted:boolean = false

    public deniedReason:string = ''

    public amount:number = 2

    public desc:string = ''

    public phone:string = ''

    public mail:string = ''

    public id:number = -1

    public parentID:string = ''

    constructor() {
        this.id = new Date().getTime()
    }

}
