import {UtilService} from './util.service';
import {Order, OrderGroup} from '../models/order.model';
import {STORAGE_MY_BASKET} from '../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { DBService } from 'src/app/services/db.service';
import { Item, Category } from 'src/app/models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  constructor(private util:UtilService, private db: DBService) { }


  loadBasket(storageKey: string = STORAGE_MY_BASKET) {
    let basket: any = localStorage.getItem(storageKey + "/" + this.db.currentRestaurantKey)
    if (basket) basket = JSON.parse(basket)
    if (basket && basket.order) {

      this.util.enableBonuskarten = false;

      this.db.basket = new OrderGroup(basket.id)
      this.db.basket.restaurantKey = this.db.currentRestaurantKey

      this.setBasketMode(basket)

      let item: Item
      let parentCat: Category
      let grandparentCat: Category
      //TODO wenn es 2 Schichten sind...
      for (let order of basket.order) {
        //nicht mehr existierende Items werden nicht geladen
        item = this.db.findItem(order.itemKey)
        parentCat = this.db.findCategory(item.id)
        grandparentCat = this.db.findParentCategory(parentCat)

        if (item != null && item.isActive && parentCat != null && parentCat.isActive){
          if(item.parentCatID == item.rootCatID || (grandparentCat != null && grandparentCat.isActive)){
            this.db.basket.order.push(order)
            //TODO: Extra + Option Price nach wie vor: einmal drin für immer
          }
        }
      }

      return true;
    } 
    
    return false;
  }

  setBasketMode(basket) {
    //if restaurant doesnt support basket order mode anymore, select other default settings ...
    if (this.db.currentRestaurant.orderMode[basket.mode] != true) {
      if (this.db.currentRestaurant.orderMode["eat-here"] != true) {
        if (this.db.currentRestaurant.orderMode["take-away"] == true) this.db.basket.mode = "take-away"
        else this.db.basket.mode = "delivery"
      } else {
        this.db.basket.mode = "eat-here"
      }
    }else{
      this.db.basket.mode = basket.mode
    }
  }
}
