import { DBService } from './db.service';
import { Injectable } from '@angular/core';
import { Restaurant } from 'src/app/models/restaurant.model';
import { Bonuskarte } from 'src/app/models/bonuskarte.model';
import { Category } from 'src/app/models/menu.model';
import { UtilService } from 'src/app/services/util.service';
import { OrderGroup } from '../models/order.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BonuskarteService {

  constructor(private db: DBService, public util: UtilService, private userService:UserService) { }

  getFullBonuskartenIDs(restaurant: Restaurant): Bonuskarte[] {
    let bonuscards: Bonuskarte[] = []
    let usedItems: number = 0

    for (let bonuskarte of restaurant.bonuskarten) {
      usedItems = this.getCurrentItemsForBonuskarte(bonuskarte)
      while ((bonuskarte.requiredAmount - usedItems) <= 0) {
        bonuscards.push(Object.assign(new Bonuskarte(restaurant.id), bonuskarte)) //Kopie jeweils erstellen
        usedItems -= bonuskarte.requiredAmount
      }
    }

    return bonuscards
  }

  itemBelongs2Karte(itemID: number, bonuskarte: Bonuskarte): boolean {
    if (itemID === bonuskarte.idAsBonus) {
      return true
    }

    //Kategorie durchsuchen
    let cat: Category = this.db.findCategory(itemID)
    if (cat) {
      if (cat.id === bonuskarte.idAsBonus) {
        return true
      } else {
        //Testen, vll. ist es parent category
        let parentCat: Category = this.db.findParentCategory(cat)
        if (parentCat) {
          if (parentCat.id === bonuskarte.idAsBonus) {
            return true
          }
        }
      }
    }
    return false
  }

  markUsedOrders(basket: OrderGroup) {

    let filledBonuscards: Bonuskarte[] = this.getFullBonuskartenIDs(this.db.currentRestaurant);
    let usedIndexes: { [id: number]: boolean } = {};
    for (let i = 0; i < 99; i++) usedIndexes[i] = false
    //this.util.debug("xxx",basket)
    //this.util.debug("yyy",basket.order)
    /*** Alle Orders anschauen, die einer Bonuskarte zugewiesen sind und schauen welche Karten es waren ***/
    for (let order of basket.order) {
      if (order.bonuscardPrice === -1) continue
      if (!order.amountPartInBonus) order.amountPartInBonus = 0

      order.amountPartInBonus = order.amount //Orders, die durch Bonus gewonnen werden, duerfen nicht fuer eine weitere Bonuskarte verfuegbar sein
      for (let i = 0; i < filledBonuscards.length; i++) {
        if (usedIndexes[i] === true) continue;
        if (this.itemBelongs2Karte(order.itemKey, filledBonuscards[i])) {
          usedIndexes[i] = true
          //Jetzt alle Orders die diese Bonuskarte gefuellt hat, auf Done setzen
          this.setAllOrdersForCard2Done(filledBonuscards[i])
          break;
        }
      }
    }

    this.db.updateOrderGroup(basket)
  }

  private setAllOrdersForCard2Done(bonuskarte: Bonuskarte) {
    let currentAmount: number = 0
    for (let orderGroup of this.db.myPrivateOrders) {
      if (orderGroup.timestamp < bonuskarte.startDate || orderGroup.timestamp > bonuskarte.endDate) continue
      if(this.db.currentRestaurant.clientMustJoinBonuskarte && this.userService.getBonuscard(bonuskarte.id) > orderGroup.timestamp) continue //dann war die Bestellung bevor er zur Bonuskarte kam; Diese dann nicht dazu zaehlen

      for (let order of orderGroup.order) {
        if (!order.amountPartInBonus) order.amountPartInBonus = 0
        if (order.amountPartInBonus >= order.amount) continue //wenn es schon komplett benutzt wurde, dann nicht mehr

        if (order.state == 2) {
          if (order.itemKey === bonuskarte.id2Collect) {
            while (order.amountPartInBonus < order.amount && bonuskarte.requiredAmount > currentAmount) {
              order.amountPartInBonus += 1
              currentAmount += 1
            }

            this.db.updateOrderGroup(orderGroup)
            if (currentAmount >= bonuskarte.requiredAmount) return
            continue
          }

          if (!bonuskarte.collectCategroies) {
            continue //Wenn es keine Kategorie ist und nicht oben getroffen hat, ist es nicht dabei
          }

          //Kategorie durchsuchen
          let cat: Category = this.db.findCategory(order.itemKey)
          if (!cat) continue

          if (cat.id === bonuskarte.id2Collect) {
            while (order.amountPartInBonus < order.amount && bonuskarte.requiredAmount > currentAmount) {
              order.amountPartInBonus += 1
              currentAmount += 1
            }

            this.db.updateOrderGroup(orderGroup)
            if (currentAmount >= bonuskarte.requiredAmount) return
            continue
          } else {
            //Testen, vll. ist es parent category
            let parentCat: Category = this.db.findParentCategory(cat)
            if (!parentCat) continue
            if (parentCat.id === bonuskarte.id2Collect) {
              while (order.amountPartInBonus < order.amount && bonuskarte.requiredAmount > currentAmount) {
                order.amountPartInBonus += 1
                currentAmount += 1
              }

              this.db.updateOrderGroup(orderGroup)
              if (currentAmount >= bonuskarte.requiredAmount) return
              continue
            }
          }
        }
      }
    }
  }

  isValid(bonuskarte: Bonuskarte):boolean{
    if (!bonuskarte.isActive) return false;
    let currentTime:number = new Date().getTime()

    return (currentTime >= bonuskarte.startDate && currentTime < bonuskarte.endDate)
  }

  //onlySafeOrders = die auch im System bestaetigt sind
  //Diese Methode wird immer aufgerufen
  getCurrentItemsForBonuskarte(bonuskarte: Bonuskarte, onlySafeOrders: boolean = true): number {
    if (!bonuskarte.isActive) return 0;
    if(this.db.currentRestaurant.clientMustJoinBonuskarte && this.userService.getBonuscard(bonuskarte.id) === -1) return 0; //dann ist er nicht in der bonuskarte
    let amount = 0;

    this.util.debug("1 getCurrentItemsForBonuskarte", amount, this.db.myPrivateOrders)

    this.util.log("Bonus: Alle private Orders = ", this.db.myPrivateOrders)
    for (let orderGroup of this.db.myPrivateOrders) {
      this.util.log("Bonus: OrderGroup = ", orderGroup)
      if (orderGroup.timestamp < bonuskarte.startDate || orderGroup.timestamp > bonuskarte.endDate) continue
      if(this.db.currentRestaurant.clientMustJoinBonuskarte && this.userService.getBonuscard(bonuskarte.id) > orderGroup.timestamp) continue //dann war die Bestellung bevor er zur Bonuskarte kam

      for (let order of orderGroup.order) {
        if (!order.amountPartInBonus) order.amountPartInBonus = 0

        this.util.debug("2 getCurrentItemsForBonuskarte", order, amount)
        if (order.amountPartInBonus >= order.amount) continue  //wenn es schonmal benutzt wurde, dann nicht mehr

        if (order.state == 2 || !onlySafeOrders) {
          //Wenn es ein Item ist....
          if (order.itemKey === bonuskarte.id2Collect) {
            this.util.debug("3")
            amount += (order.amount - order.amountPartInBonus);
            continue
          }

          if (!bonuskarte.collectCategroies) {
            continue //Wenn es keine Kategorie ist und nicht oben getroffen hat, ist es nicht dabei
          }

          //Kategorie durchsuchen
          let cat: Category = this.db.findCategory(order.itemKey)
          if (!cat) continue

          if (cat.id === bonuskarte.id2Collect) {
            this.util.debug("4")
            amount += (order.amount - order.amountPartInBonus);
            continue
          } else {
            //Testen, vll. ist es parent category
            let parentCat: Category = this.db.findParentCategory(cat)
            if (!parentCat) continue
            if (parentCat.id === bonuskarte.id2Collect) {
              this.util.debug("5")
              amount += (order.amount - order.amountPartInBonus);
              continue;
            }
          }
        }
      }
    }

    this.util.debug("xxx", amount)
    return amount;
  }

  public hasActiveBonuskarten(restaurant: Restaurant): boolean {
    if (!restaurant || !restaurant.bonuskarten || restaurant.bonuskarten.length === 0) return false

    for (let bonuskarte of restaurant.bonuskarten) {
      if (bonuskarte.isActive) return true
    }
    return false
  }

}
