export class CoronaGroup {
    
    id
    
    timestamp: number;

    place: string;

    guests: Corona[] = [];

    from: number = -1

    until: number = -1

    checkedIn: boolean = false

    constructor(public restaurantId: string) {
        this.timestamp = new Date().getTime()
        this.from = new Date().getTime()
    }

}

export class Corona {

    name: string = " ";

    phone: string;
    
    address: string;

    geimpft: boolean;
    
    getestet: boolean;

    genesen: boolean;

    constructor() {

    }

}