import { PopoverController, ModalController, Platform } from '@ionic/angular';
import { PopoverComponent } from '../components/popover/popover.component';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Events } from 'src/app/services/events.service';
import { AlertService } from 'src/app/services/alert.service';
import { APP_VERSION } from 'src/environments/constant';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  //dbversion:string = "vEngelstueble" 
  //dbversion:string = "vTest"
  dbversion: string = "v2"
  demo: boolean = false;
  isAdmin: boolean = false;
  loggingEnabled: boolean = true;
  logsPushEnabled: boolean = false;
  isVersionTooOld: boolean = false
  randomNumber: number;
  public randAlphabet: string

  sourceParam: string = undefined
  targetParam: string = undefined
  scanSub
  remindToOpenRestaurantPersonally: boolean = false;
  informAboutOrdersViaPush: boolean = false;

  testdate: any;

  enableBonuskarten: boolean = false; //TODO seperate Bonuskarten
  wifiConnectionAvailable: boolean = false;

  public employeeMode: boolean = false

  reservationUI: string = "overview"

  orderUi: string = "current"
  changeOrderUi(orderUi: string) {
    this.orderUi = orderUi
  }

  batteryLevel: any = 50;


  constructor(private toastCtrl: ToastController, public popoverController: PopoverController, public modalController: ModalController, private events: Events,
    public platform: Platform, public a: AlertService) {
      this.randomNumber = Math.random()
      this.randAlphabet = this.getRandomString();
  }

  getRandomString() {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for ( var i = 0; i < 26; i++ ) {
      let char = randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      while(result.indexOf(char) > -1)
        char = randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      result += char;
    }
    return result;
}


  setIsVersionTooOld(minVersion: string) {
    minVersion = minVersion.replace(".", "").replace(".", "")
    let currentVersion: string = APP_VERSION.replace(".", "").replace(".", "")

    this.isVersionTooOld = (+minVersion > +currentVersion)// && this.isMobileApp2 ((kann nicht passieren))
  }

  get p_platform(): any[] {
    return this.platform.platforms()
  }

  isMobileApp(): boolean {
    return false; //Workaround, damit immer der back Pfeil angezeigt wird...
    // return this.platform.is('ios') || this.platform.is('android');
  }

  get isMobileApp2(): boolean {
    return (this.platform.is('ios') || this.platform.is('android')) && (this.platform.is('cordova') || this.platform.is('capacitor'));
  }

  get isMobileWeb(): boolean {
    return (this.platform.is('ios') || this.platform.is('android')) && !(this.platform.is('cordova') || this.platform.is('capacitor'));
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  roundTime5Minutes(time) {
    var timeToReturn = new Date(time);

    timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 5) * 5);
    return timeToReturn;
  }

  isIOS(): boolean {
    return this.platform.is('ios')
  }

  hasEmptyFields(stringArray: string[]): boolean {
    if (!stringArray) return true

    for (let element of stringArray) {
      if (!element || element.trim().length <= 0) return true
    };
    return false
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public formatPrice(price: number): any {
    if (!price) price = 0;
    return ((Math.round(price * 100) / 100).toFixed(2));
  }

  public roundPrice(price: number): number {
    if (!price) return 0;
    return ((Math.round(price * 100) / 100));
  }

  /**
   * Normalerweise hh:mm, ausser wenn es nicht der gleiche Tag ist => Dann auch Datum
   * @param dateInMillis 
   */
  public formatDate(dateInMillis): string {
    if (!dateInMillis) return '';
    let date = new Date(dateInMillis);
    let today: Date = new Date();
    let stringDate: string = ""

    if (!(today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear())) {
      stringDate = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " - "
    }

    stringDate += (("0" + (date.getHours())).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2));

    return stringDate
  }


  async presentToast(msg: string, _duration: number = 3000, cssClass: any = 'toast-success') {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: _duration,
      position: 'top',
      cssClass: cssClass,
      buttons: [{
        side: 'end',
        text: 'Ok',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
      ]
    });

    toast.present();
  }
 
  async presentAppToast() {
    let toast = await this.toastCtrl.create({
      message: "<a href='https://barela.de/appstore.html' target='_blank'>Hole dir jetzt die Barela App um nichts zu verpassen</a>",
      duration: 60000,
      position: 'bottom',
      cssClass: 'app-toast',
      buttons: [{
        side: 'end',
        icon: 'close',
        text: 'Schließen',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
      ]
    });

    toast.present();
  }


  public isPopupOpen: boolean = false;
  public isModalOpen: boolean = false;
  public timeWhenAs2AddBasket: number = -1;
  public timeWhenSureForYorOder: number = -1;
  public timeWhenSureToDelete: number = -1;
  public timeWhenAskForPermissions: number = -1;
  public timeWhenSureCallingService: number = -1;

  public lastPopupCategory: string = '';


  popover
  async popup(color: string, text: any, category: string = 'plain', timeout: number = 2500) {
    this.lastPopupCategory = category
    this.popover = await this.popoverController.create({
      component: PopoverComponent,
      componentProps: {
        message: 'message', popoverController: this.popoverController,
        popupCategory: category,
        popupData: text,
        popupTimeout: timeout, popupColor: color
      },
      translucent: true
    });

    this.isPopupOpen = true;

    await this.popover.present();

    await this.popover.onDidDismiss().then((res: any) => {
      if (this.lastPopupCategory === 'sureForYorOder') this.events.publish('sureForYorOder-' + this.timeWhenSureForYorOder, res)
      if (this.lastPopupCategory === 'addToBasket') this.events.publish('addToBasketPopoverDismissed-' + this.timeWhenAs2AddBasket, res)
      if (this.lastPopupCategory === 'sureToDelete') this.events.publish('sureToDelete-' + this.timeWhenSureToDelete, res)
      //if(this.lastPopupCategory === 'sureForYorOder') this.events.publish('askForPermissions-' + this.timeWhenAskForPermissions,res)
      if (this.lastPopupCategory === 'sureCallingService') this.events.publish('sureCallingService-' + this.timeWhenSureCallingService, res)

      this.isPopupOpen = false;
    })

  }

  log(msg: string, data1?: any, data2?: any, data3?: any, data4?: any) {
    if (this.loggingEnabled) console.log(msg, data1, data2, data3, data4)
  }

  debug(msg: string, data1?: any, data2?: any, data3?: any, data4?: any) {
    if (this.loggingEnabled) console.log(msg, data1, data2, data3, data4)
  }

  info(msg: string, data1?: any, data2?: any, data3?: any, data4?: any) {
    if (this.loggingEnabled) console.info(msg, data1, data2, data3, data4)
  }

  warn(msg: string, data1?: any, data2?: any, data3?: any, data4?: any) {
    if (this.loggingEnabled) console.warn(msg, data1, data2, data3, data4)
  }

  error(msg: string, data1?: any, data2?: any, data3?: any, data4?: any) {
    if (this.loggingEnabled) console.error(msg, data1, data2, data3, data4)
  }

  deleteFromArray(array: any[], key): any[] {
    console.debug("func=deleteFromArray; array; key", array, key)

    let index = array.indexOf(key);

    console.debug("func=deleteFromArray; found_index=", index)

    if (index > -1) {
      array.splice(index, 1);
    }
    return array
  }

  addToArray(array: any[], obj: any) {
    let index = array.indexOf(obj);

    if (index == -1) {
      array.push(obj)
    }
    return array
  }

  isInArray(array: any[], key: string): boolean {
    if (!array) return false
    for (let obj of array) {
      if (obj == key) {
        return true;
      }
    }
    return false;
  }


}