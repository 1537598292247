
import { Component, OnInit, ViewChild } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { Extra, Option } from 'src/app/models/menu.model';
import { IonContent } from '@ionic/angular';
import { getAllergene, getZusatzstoffe } from 'src/environments/constant';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;

  popCtrl: PopoverController;
  popover: any

  popupCategory
  popupData
  popupTimeout
  popupColor

  tmpAmount: number = 1
  tmpNote: string = ''

  extrasExpanded: boolean = false;

  public allergene: { [id: string]: any } = getAllergene()
  public zusatzstoffe: { [id: string]: any } = getZusatzstoffe();

  public getAllergeneKeys() {
    return Object.keys(this.allergene)
  }

  public getZusatzstoffeKeys() {
    return Object.keys(this.zusatzstoffe)
  }

  //@ViewChild('header') headerDiv;
  @ViewChild('footer', { static: false }) footerDiv;
  @ViewChild('content', { static: false }) contentDiv;

  ngAfterViewInit() {
    if (this.popupCategory === 'addToBasket') {
      let i = 0;
      var timer = setInterval(() => {
        if (i++ === 30) clearInterval(timer);
        let footerHeight = this.footerDiv.el.offsetHeight;
        let contentHeight = this.contentDiv.el.offsetHeight;
        console.log(contentHeight)
        if (contentHeight > 0) {
          this.contentDiv.el.setAttribute("style", "height:" + (contentHeight - footerHeight) + "px !important;");
          clearInterval(timer);
          var footer = document.getElementById("footer");
          if (footer) footer.scrollTop = footer.scrollHeight;
        }
      }, 200);
    }
  }

  bemerkungFocused() {
    setTimeout(() => {
      var content = document.getElementById("content");
      if (content) content.scrollTop = content.scrollHeight;
    }, 500)
  }

  changeAmount(amount: number) {
    this.tmpAmount += amount
    if (this.tmpAmount < 1) this.tmpAmount = 1
    if (this.tmpAmount > 99) this.tmpAmount = 99
    this.amountChanged(this.tmpAmount)
  }

  constructor(private navParams: NavParams) {
    this.popCtrl = navParams.get('popoverController');
    this.popover = navParams.get('popover');

    this.popupCategory = navParams.get('popupCategory');
    this.popupData = navParams.get('popupData');
    this.popupTimeout = navParams.get('popupTimeout');
    this.popupColor = navParams.get('popupColor');

    if (this.popupData && this.popupData.length > 1) {
      if (this.popupData[1].extras) {
        for (let e of this.popupData[1].extras) {
          e.isSelected = false
        }
      }

      if (this.popupData[1].options) {
        for (let i = 0; i < this.popupData[1].options.length; i++) {
          this.popupData[1].options[i].isSelected = false
          if (i == 0) this.popupData[1].options[i].isSelected = true
        }
      }
    }

    var result2 = document.getElementsByClassName("popover-content");
    if (result2 && result2.length > 0) {
      if (this.popupColor === "red") {
        result2[0].classList.add("popover-red")
      } else if (this.popupColor === "green") {
        result2[0].classList.add("popover-green")
        result2[0].classList.add("white")
      }
    }
  }

  public formatPrice(price: number): any {
    if (!price) price = 0;
    return ((Math.round(price * 100) / 100).toFixed(2));
  }

  get textAllergene(): string {
    let result: string = ""

    for (let key of Object.keys(this.allergene)) {
      if (this.popupData[1] && this.popupData[1].allergenic && this.allergene[key] && this.popupData[1].allergenic.includes(key)) {
        result += this.allergene[key].name + " | "
      }
    }

    return result.substring(0, result.lastIndexOf(" | "))
  }

  get textZusatzstoffe(): string {
    let result: string = ""

    for (let key of Object.keys(this.zusatzstoffe)) {
      if (this.popupData[1] && this.popupData[1].additives && this.zusatzstoffe[key] && this.popupData[1].additives.includes(key)) {
        result += this.zusatzstoffe[key].name + " | "
      }
    }

    return result.substring(0, result.lastIndexOf(" | "))
  }

  clickExtra(extra: Extra) {
    extra.isSelected = !extra.isSelected
  }

  clickOption(option: Option) {
    for (let i = 0; i < this.popupData[1].options.length; i++) {
      this.popupData[1].options[i].isSelected = false
    }

    option.isSelected = !option.isSelected
  }

  ngOnInit() {
    if (this.popupTimeout === -1) return
    let myRef = this
    setTimeout(function () {
      myRef.dismiss()  //falls Popups bestehen bleiben sollen, muss diese Zeile auskommentiert werden
    }, this.popupTimeout);

    var result = document.getElementsByClassName("popover-content");
    if (result && result.length > 0) {
      if (this.popupColor === "red") {
        result[0].classList.add("popover-red")
      } else {
        result[0].classList.add("popover-green")
      }
    }
  }

  toggleExtrasExpanded() {
    this.extrasExpanded = !this.extrasExpanded;
  }

  removeAmount() {
    this.tmpAmount = null
  }

  get selectedOption(): string {
    if (this.popupData && this.popupData.length > 1 && this.popupData[1].options) {
      for (let i = 0; i < this.popupData[1].options.length; i++) {
        if(this.popupData[1].options[i].isSelected == true)
          return this.popupData[1].options[i].name
      }
    }
    return undefined
  }

  get selectedOptionSinglePrice(): number {
    if (this.popupData && this.popupData.length > 1 && this.popupData[1].options) {
      for (let i = 0; i < this.popupData[1].options.length; i++) {
        if(this.popupData[1].options[i].isSelected == true)
          return this.popupData[1].options[i].price
      }
    }
    return 0
  }

  get extraNames(): string {
    let extras: string = ''
    if (this.popupData && this.popupData.length > 1 && this.popupData[1].extras) {
      for (let e of this.popupData[1].extras) {
        if (e.isSelected) extras += ', ' + e.name
      }
      if (extras.length > 0) extras = extras.substring(2)
      else return undefined
    }

    return extras;
  }

  get extraSinglePrice(): number {
    let extraSinglePrice: number = 0
    if (this.popupData && this.popupData.length > 1 && this.popupData[1].extras) {
      for (let e of this.popupData[1].extras) {
        if (e.isSelected) extraSinglePrice += e.price
      }
    }

    return extraSinglePrice;
  }

  public updatedCattPrice(price: number): any {
    if (!price) price = 0;
    return ((Math.round(price * 100) / 100).toFixed(2));
  }

  amountChanged(amount: number) {
    let elements: any = document.getElementsByTagName("ion-input")
    if (elements && elements.length > 0) {
      for (let element of elements) {
        setTimeout(() => {
          try {
            if (element.value.split(".").length > 0) element.value = element.value.split(".")[0]
            if (element.value > 99) element.value = 99
            if (element.value < 0) element.value = 0
          } catch (error) {
            console.debug("element value cant be splitted", element.value)
          }
        }, 200)
      }
    }
  }

  dismiss(data: any = false) {
    console.log("Dismiss Popup; Data: ", data)
    this.popCtrl.dismiss(data)
  }


}
