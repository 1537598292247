import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { SettingsProvider } from 'src/app/services/settings.service';
import { AlertInput } from '@ionic/core';
import { NFC, Ndef } from '@ionic-native/nfc/ngx';

@Component({
  selector: 'app-qrscan-info',
  templateUrl: './qrscan-info.component.html',
  styleUrls: ['./qrscan-info.component.scss']
})
export class QRScanInfoComponent implements OnInit {
  lightOn: boolean = false
  /** -1 = switched off; 0 = switched on, but not selected; 1 = selected and switched on */
  nfcState = -1;
  intervalID = undefined

  constructor(private modalCtrl: ModalController, public util: UtilService, private qrScanner: QRScanner, private settings: SettingsProvider,
    private alertCtrl: AlertController, private nfc: NFC, private ndef: Ndef) { }

  ngOnInit() {
    this.settings.setActiveTheme('qr-theme');
    this.util.isModalOpen = true
    let myRef = this

    this.intervalID = setInterval(function () {
      myRef.nfc.enabled().then(val => {
        if (myRef.nfcState === -1) { //kann gar nicht mehr inaktiv sein
          myRef.nfcState = 0
        }
      }, err => {
        myRef.nfcState = -1
      })
    }, 3000);
  }

  ngOnDestroy() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }

  }




  enterManualVisible: boolean = true;
  ngAfterViewInit() {
    this.scan()
    this.initNfc(0)

    // setTimeout(() => {
    //  this.enterManualVisible = true
    // }, 5000);
  }

  /*********************************** */
  // ### NFC ###
  nfcListenerAdded: boolean = false;
  initNfc(futureState: number) {
    if (this.nfcListenerAdded) {
      this.nfcState = futureState
      return;
    }

    this.nfc.addNdefListener(() => {
      this.nfcState = futureState
      this.nfcListenerAdded = true;
      this.util.log('successfully attached ndef listener');
    }, (err) => {
      this.util.error('error attaching ndef listener', err);
      //this.util.presentToast('NFC kann leider auf deinem Telefon nicht automatisch aktiviert werden',3000,'toast-warning')
      this.nfcState = -1;
    }).subscribe((event) => {
      this.util.log('received ndef message. the tag contains: ', event.tag);
      this.util.log('decoded tag id', this.nfc.bytesToHexString(event.tag.id));

      if (event.tag && event.tag.ndefMessage && event.tag.ndefMessage.length >= 0 && event.tag.ndefMessage[0].payload) {
        let value: string = '';
        for (let load of event.tag.ndefMessage[0].payload) {
          value += ((load - 48) + '') //ASCII Code
        }
        this.util.log('yyy', value);
        this.dismiss(value)
      }

    });


  }

  clickSmallImg() {
    this.nfc.enabled().then(val => {
      if (this.nfcState === -1) { //kann gar nicht mehr inaktiv sein
        this.nfcState = 0
        this.initNfc(1);
      } else if (this.nfcState === 0) {
        this.initNfc(1);
      } else {
        this.nfcState = 0 //Falls QR Code jetzt an der Reihe ist
      }
    }, err => {
      this.nfcState = -1
      this.showNFCSettings()
    })

  }

  showNFCSettings() {
    this.nfc.showSettings().then(val => {
      this.util.log("After settings: " + val)
      if (val) {
        this.initNfc(1);
      } else {
        this.nfcState = -1
      }
    })
  }

  //
  //nfc.enabled(onSuccess, onFailure);


  /************************************ */
  async enterManually() {
    let input: AlertInput = {
      name: 'tablenumber',
      type: 'number',
      placeholder: ''
    }

    const alert = await this.alertCtrl.create({
      header: 'Tischnummer manuell eingeben',
      message: 'Du kannst die Tischnummer des Aufstellers hier eingeben',
      cssClass: 'myAlert',
      inputs: [
        input
      ],
      buttons: [
        {
          text: 'Abbrechen',
          cssClass: 'alert-button alert-button-qr',
          role: 'cancel',
          handler: () => {
            this.util.log('Confirm Cancel');
          }
        }, {
          text: 'OK',
          cssClass: 'alert-button alert-button-qr',
          handler: (data) => {
            this.dismiss(data.tablenumber);
          }
        }
      ]
    });
    await alert.present();

    let elements: any = document.getElementsByClassName("alert-input")
    if (elements && elements.length > 0) {
      for (let element of elements) {
        element.autofocus = true;
        element.focus()
      }
    }
  }

  dismiss(data?) {
    this.qrScanner.disableLight()
    this.settings.setActiveTheme(this.settings.lastActive);
    this.util.isModalOpen = false
    this.qrScanner.hide();
    if (this.util.scanSub) {
      this.util.scanSub.unsubscribe()
    }
    this.qrScanner.destroy()

    //Switch between URL or number
    if(data){
      if((""+data).length > 10){
        //Dann ist es eine URL, z.B.: https://play.google.com/store/apps/details?id=de.barela&gl=CH?12
        let items:string[] = (''+data).split('?')
        if(items.length > 0) data = items[items.length -1]
      }
    }

    this.modalCtrl.dismiss(data);
  }

  dismissWithoutCamPermission(data?) {
    this.settings.setActiveTheme(this.settings.lastActive);
    this.util.isModalOpen = false
    if (this.util.scanSub) {
      this.util.scanSub.unsubscribe()
    }
    this.qrScanner.destroy()
    this.modalCtrl.dismiss(data);
  }

  toggleLight() {
    this.lightOn = !this.lightOn
    if (this.lightOn) {
      this.qrScanner.enableLight()
    } else {
      this.qrScanner.disableLight()
    }
  }


  scan() {
    // Optionally request the permission early
    try {
      this.qrScanner.prepare()
        .then((status: QRScannerStatus) => {
          if (status.authorized) {
            // camera permission was granted
            this.util.log("Authorized")

            this.qrScanner.hide(); // hide camera preview

            this.util.log("Scan")
            // start scanning
            this.util.scanSub = this.qrScanner.scan().subscribe((text: any) => {
              this.util.log('Scanned something', text);

              this.qrScanner.hide(); // hide camera preview
              this.util.scanSub.unsubscribe(); // stop scanning

              if (text && text.result) {
                this.dismiss(text.result);
              } else {
                this.dismiss(text);
              }

            });

          } else if (status.denied) {
            this.util.log("Denied Permanently")
            // camera permission was permanently denied
            // you must use QRScanner.openSettings() method to guide the user to the settings page
            // then they can grant the permission from there
            this.dismiss();

          } else {
            this.util.log("Denied")
            this.dismiss();
            // permission was denied, but not permanently. You can ask for permission again at a later time.
          }
        })
        .catch((e: any) => {
          this.util.log('Error is', e)
          this.dismissWithoutCamPermission();
        });
    } catch (e) {
      this.util.log('Error 2 is', e)
      this.util.presentToast('Fehler beim Scannen',3000,'toast-warning')
      this.dismissWithoutCamPermission();
    }
  }

}
