import { PipeTransform, Pipe } from "@angular/core";

@Pipe({name: 'sortExtraOption'})
export class SortExtraOptionPipe implements PipeTransform {

  transform(arr: any[], args: any = undefined): any[]{
    return arr.sort((a, b) => {
      if (a.position > b.position) {
        return 1;
      }
      if (a.position < b.position) {
        return -1;
      }
      return 0;
    });
  }
}