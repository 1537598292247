import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fcmUrl } from 'src/environments/constant';
import { OrderGroup } from 'src/app/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) {

  }



  sendMail(orderGroup: OrderGroup, mail: string): Observable<any> {
    let data: any = { orderGroup: orderGroup, mail: mail }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    console.log("send mail", fcmUrl, orderGroup)

    return this.http.post<any>(`${fcmUrl}/mail/order`, data, { observe: 'response', headers: headers })
  }

  sendOrderConfirmation(orderGroup: OrderGroup, mail: string, restaurantName: string){
    if(!mail || mail.length <= 0) mail = "m.hassel@mamingo.de"
    let data: any = { orderGroup: orderGroup, mail: mail, restaurant: restaurantName }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    console.log("send mail", fcmUrl, orderGroup)

    return this.http.post<any>(`${fcmUrl}/mail/order-confirmation`, data, { observe: 'response', headers: headers })
  }

  sendMailToCustomer(header: string, body: string, mail: string): Observable<any> {
    let data: any = { header: header, body: body, mail: mail }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post<any>(`${fcmUrl}/mail/update`, data, { observe: 'response', headers: headers })
  }




}
