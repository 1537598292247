import { DisplayService } from './display.service';
import { TOPIC_NEW_CONTACT_MESSAGE } from './push.service';
import { UtilService } from './util.service';
import { Injectable, NgZone } from '@angular/core';

import * as firebase from 'firebase/app';

import { Observable } from 'rxjs';
import { DBService } from 'src/app/services/db.service';
import { LoadingController} from '@ionic/angular';
import { Events } from 'src/app/services/events.service';

import { FirebaseAuthentication } from "@ionic-native/firebase-authentication/ngx";
import { STORAGE_LAST_TIME_OWNER } from 'src/environments/environment';
import { PushService } from 'src/app/services/push.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ADMIN_MAIL_ADRESSES, OWNER } from 'src/environments/constant';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  user: Observable<firebase.User>;

  constructor(public firebaseAuth: AngularFireAuth, private db: DBService, private events: Events, private pushService: PushService,
    public util: UtilService, private loadingController: LoadingController, private ngZone: NgZone,
    private displayService: DisplayService, private router: Router, private firebaseAuthentication: FirebaseAuthentication) {
    this.user = firebaseAuth.authState;

    this.user.subscribe((val: any) => {
      if (val && val.uid) {
        if(!val.email){
          this.firebaseAuth.auth.signOut(); //only phonenumber validation, so logout directly afterwards
          return
        }

        this.db.loggedUserUID = val.uid
        //*** Set as administrator ***/
        if (val && val.email && ADMIN_MAIL_ADRESSES.indexOf(val.email) > -1) {
          this.util.isAdmin = true;
          this.pushService.unsubscribeToTopic(TOPIC_NEW_CONTACT_MESSAGE)
          this.pushService.subscribeToTopic(TOPIC_NEW_CONTACT_MESSAGE)
        }
        // this.ngZone.run(() => this.router.navigate(['/restaurant-list']))//wenn er anmeldet oder abmeldet, dann wieder zurueck
        this.events.publish('navToRoot')
      }
    })
  }




  confirmationResult
  smsSent = false
  requestSMSCodeWeb(phoneNumber: string, appVerifier: any) {
    return this.firebaseAuth.auth.signInWithPhoneNumber(phoneNumber, appVerifier)
  }

  verificationID
  requestSMSCodeIOS(phoneNumber: string) {
    return this.firebaseAuthentication.verifyPhoneNumber(phoneNumber, 0)
  }

  loginViaSMSCodeIOS(smsCode) {
    return this.firebaseAuthentication.signInWithVerificationId(this.verificationID, smsCode)
  }











  passwordReset(mail: string): any {
    return this.firebaseAuth
      .auth.sendPasswordResetEmail(mail)
  }

  async signup(email: string, password: string) {
    let myRef = this
    const loading = await this.loadingController.create({
      message: 'Registrieren...',
      duration: 10000
    });
    await loading.present();

    this.firebaseAuth
      .auth.createUserWithEmailAndPassword(email, password).then((res => {
        console.log("Erfolgreich registriert", res)

        myRef.db.loggedUserUID = res.user.uid;  

        localStorage.setItem(STORAGE_LAST_TIME_OWNER, JSON.stringify(true))

        myRef.loadingController.dismiss()

        myRef.util.presentToast("Du hast dich erfolgreich registriert.", 3000, "toast-success")

        myRef.events.publish('registered', myRef.db.loggedUserUID)
      }))
      .catch(function (error) {
        myRef.util.log('Something went wrong:', error);
        myRef.loadingController.dismiss()
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/weak-password') {
          myRef.util.presentToast("Dein gewähltes Passwort ist zu einfach.", 3000, "toast-warning")
        } else if (errorCode == 'auth/email-already-in-use') {
          myRef.util.presentToast("Deine E-Mail Adresse ist bereits in Verwendung. Bitte kontaktiere den Support.", 3000, "toast-warning")
        } else {
          myRef.util.presentToast("Bei deiner Registrierung ist etwas schief gelaufen: " + errorMessage, 3000, "toast-warning")
        }
      });
  }


  //*** Passwort des eingloggten Nutzer muss nochmals eingegeben werden ***/
  deleteUser(password: string) {
    let myRef = this
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    user.reauthenticateWithCredential(credential).then(async function () {
      myRef.db.removeRestaurantsByOwner(user.uid)

      let restaurantsFromUser: string[] = myRef.db.getRestaurantKeys(OWNER)
      let startTime: number = new Date().getTime()
      //Warten bis Restaurants geloescht sind....
      while (restaurantsFromUser && restaurantsFromUser.length > 0 && ((startTime + 1000 * 50) > new Date().getTime())) {
        restaurantsFromUser = myRef.db.getRestaurantKeys(OWNER)

        await myRef.util.delay(500);
      }

      // User re-authenticated.
      var user = firebase.auth().currentUser;

      user.delete().then(function () {
        // User deleted.
        myRef.logout()
        myRef.events.publish('navToRoot')
        //TODO: provide possibility to delete restaurant (or maybe it should be done with us (also user??))
      }).catch(function (error) {
        myRef.util.error("2017", error)
        myRef.util.presentToast("(Code 2017) Es ist etwas schief gelaufen: " + error, 3000, "toast-warning")
      });
    }).catch(function (error) {
      myRef.util.error("4093", error)
      myRef.util.presentToast("(Code 4093) Es ist etwas schief gelaufen: " + error, 3000, "toast-warning")
    });
  }


  reauthanticate(password: string) {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    return user.reauthenticateWithCredential(credential)
  }


  async login(email: string, password: string) {
    email = email.trim().toLowerCase()
    const loading = await this.loadingController.create({
      message: 'Anmelden...',
      duration: 10000
    });
    await loading.present();

    this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        this.util.log('Erfolgreich angemeldet!', value);
        this.db.loggedUserUID = value.user.uid;    

        localStorage.setItem(STORAGE_LAST_TIME_OWNER, JSON.stringify(true))

        this.loadingController.dismiss()

        this.util.popup('green', 'Erfolgreich angemeldet')

        if (ADMIN_MAIL_ADRESSES.indexOf(email) > -1) {
          this.util.isAdmin = true;
          this.pushService.unsubscribeToTopic(TOPIC_NEW_CONTACT_MESSAGE)
          this.pushService.subscribeToTopic(TOPIC_NEW_CONTACT_MESSAGE)
        }

        this.events.publish('navToRoot')
      })
      .catch(err => {
        this.util.log('Something went wrong:', err.message);

        this.loadingController.dismiss()

        this.util.popup('red', 'Oops, da ist wohl was schiefgelaufen!', 'plain')
      });
  }

  logout() {

    //TODO
    //this.pushService.unsubscribeToTopic(TOPIC_NEW_CONTACT_MESSAGE)

    this.db.loggedUserUID = null; 

    localStorage.setItem(STORAGE_LAST_TIME_OWNER, JSON.stringify(false))

    this.util.isAdmin = false

    this.firebaseAuth.auth.signOut();

    this.ngZone.run(() => this.router.navigate(['/restaurant-list']))
  }

}