export class Menu {

    public category: Category[] = []

    public service: ServiceItem[] = []

    constructor(public id: string) {
        //this.category.push(new Category("Vorspeisen", 0));
        //    this.category.push(new Category("Hauptgänge", 1));
        //    this.category.push(new Category("Nachspeisen", 2));
    }

}

export class Category {
    public id: number;
    public parentCatID: number;
    public items: Item[] = []
    public categories: Category[] = []
    public isActive:boolean = true;
    public info: string;
    public extras: Extra[] = []
    public options: Option[] = []

    constructor(public name: string, public position: number, parentCatID?: number) {
        this.id = new Date().getTime();
        this.parentCatID = parentCatID
        if(!this.parentCatID || this.parentCatID === -1) this.parentCatID = -1
    }
}

export class Item {
    public id: number;
    
    public parentCatID: number;
    
    public rootCatID: number;  //could be the same with parent, except when it is from sub-cat
           
    public info: string;

    public allergenic: string = '';

    public additives: string = '';

    public extras: Extra[] = []

    public options: Option[] = []

    public isActive:boolean = true;

    public isSpecialPriceActive:boolean = false;

    public specialPrice: number;

    public takeAwayPrice: number = -1;

    constructor(public name: string, public price: number, public position: number, parentCatID:number, rootCatID?:number) {
        this.id = new Date().getTime();
        this.parentCatID = parentCatID
        this.rootCatID = rootCatID

        if(!this.rootCatID || this.rootCatID === -1) this.rootCatID = this.parentCatID
    }
}

export class Extra {
    public isActive:boolean = true;
    public isSelected:boolean = true; //wird nur fuer die Auswahl benoetigt

    constructor(public name: string, public price: number, public position: number = 0) {

    }
}


export class Option {
    public isActive:boolean = true;
    public isSelected:boolean = true; //wird nur fuer die Auswahl benoetigt
    
    constructor(public name: string, public price: number = 0, public position: number = 0) {

    }
}


export class ServiceItem extends Item {
    
    public color: string = 'servicered';

    public info: string;//todo braucht man den???

    constructor(name: string, position: number) {
        super(name, 0, position, -1);
        this.info = null;
    }

}